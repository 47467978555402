/**
 * DB table model
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';

export class DbTable extends AccessModel {
    static entity = 'database_tables'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        db_id: "int",
        is_static_data: "int",
        name: "string",
        description: "string",
        unique_id: "string",
        table_schema: "json",
        table_data: "json",
        test_table_data: "json",
        permissions: "json",
        remote_module_id: "int",
        physical_delete: "int",
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkDbTableAccess(await this.getAccessId(row, 'db_id'), row?.id) ? row : false
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'db-tables': {
                subscribe: ({db_id}) => db_id,
                init: async ({db_id}) => this.getList(db_id),
            },
            'app-tables': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => DbTable.getVersionTables(app_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(db_id) {
        return this.query().where({db_id}).get()
    }

    /**
     * Get version list
     */
    static getVersionTables(app_id) {
        return DbTable.query().join("app_databases").on("app_databases.id", "db_id").where("app_databases.app_id", app_id).get()
    }

    /**
     * Get module tables
     */
    static getModuleTables(module_id) {
        return DbTable.query(['database_tables.*'])
          .join("app_databases")
          .on("app_databases.id", "database_tables.db_id")
          .where("app_databases.module_id", module_id)
          .get()
    }
}
