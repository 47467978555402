<template>
  <q-page class="component-list">

    <modal-dialog ref="editComponent" title="New component" persistent>
      <component-form :edit-component="editComponent" :product="product" @closed="$refs.editComponent.hide()"/>
    </modal-dialog>

    <div class="row">
      <div class="col q-ml-sm q-mr-sm">
        <q-input class="full-width" placeholder="Search string" v-model="searchString"/>
      </div>
      <q-space/>
      <q-tabs v-model="tab" align="right">
        <q-tab name="active" label="Active"></q-tab>
        <q-tab name="archived" label="Archived"></q-tab>
      </q-tabs>
    </div>

    <div class="row q-gutter-md q-ma-sm">

      <template v-for="(component, k) of currentComponents" :key="k">

        <q-card class="col-md-3 col-xs-12">
          <q-card-section :class="`component-${component.diagram_type}`">
            <div class="text-subtitle1 row">
              <div>
                <q-btn icon="edit" size="sm" class="q-mr-sm" round flat @click="editComponentDiagram(component)"/>
              </div>
              <div class="col">
                <div class="ellipsis">{{ component.title }}</div>
                <div class="text-subtitle2">{{ component.diagram_type }}</div>
              </div>

              <div>
                <q-btn round size="sm" flat icon="menu">
                  <q-menu>
                    <q-list style="min-width: 100px">

                      <q-item clickable v-close-popup
                              @click="editComponent = component.data();$refs.editComponent.show()">
                        <q-item-section>Edit</q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="deleteComponent(component)">
                        <q-item-section>Delete</q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="copyComponent(component)">
                        <q-item-section>Copy</q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="openAnalytics(component)">
                        <q-item-section>Open analytics</q-item-section>
                      </q-item>

                    </q-list>
                  </q-menu>
                </q-btn>
              </div>

            </div>
          </q-card-section>

          <q-card-section class="row ellipsis-3-lines q-mb-md" v-html="component.description?.replaceAll?.('\n', '<br>')"/>
        </q-card>

      </template>

    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab fab icon="add" color="primary" direction="left">
        <q-fab-action v-if="componentIdToPaste" icon="content_paste" label="Paste component" color="secondary"
                      @click="pasteComponent"/>
        <q-fab-action icon="add" label="Add new" color="accent"
                      @click="editComponent={diagram_type: 'component', status: 'active', app_id: this.app_id, module_id: this.module_id, unique_id: nanoid(10)};$refs.editComponent.show()"/>
      </q-fab>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>

//Implement paste diagram

import ComponentForm from "./ComponentForm";
import {Diagram} from "@/../../common/db/Diagram.js"
import {AppModule} from "@/../../common/db/AppModule.js"
import {nanoid} from "nanoid";
import {Localization} from '../../../../../../common/db/Localization';
import {LocalizationMessage} from '../../../../../../common/db/LocalizationMessage';
import TabCommunicationMixin from '@/mixins/TabCommunicationMixin';
import ModalDialog from "@/components/ModalDialog/ModalDialog.vue";

export default {
  name: 'ComponentsList',
  components: {ModalDialog, ComponentForm},

  mixins: [TabCommunicationMixin],

  props: ['product'],
  data: () => ({
    searchString: "",
    editComponent: false,
    app_id: false,
    tab: "active",
    module_id: false
  }),

  /**
   * Created hook
   * @return {Promise<void>}
   */
  async created() {

    // Get app id from the route
    this.app_id = this.$route.params.app_id
    this.module_id = this.$route.params.module_id

    // Subscribe to modules
    await Diagram.remote().subscribe("app-module-diagrams", {module_id: this.module_id})

    // Subscribe to localizations
    await Localization.remote().subscribe("module-localizations", {module_id: this.module_id})
    await LocalizationMessage.remote().subscribe("module-localization-messages", {module_id: this.module_id})

    // Subscribe to modules
    await AppModule.remote().subscribe("app-modules", {app_id: this.app_id})

  },

  computed: {

    currentComponents() {
      return this.components?.filter(c => c.status === this.tab &&
          (!this.searchString ||
              (c.title + " " + c.description).toLowerCase().includes(this.searchString.toLowerCase())
          ))
    },

    /**
     * Return component id from the local storage
     * @returns {string}
     */
    componentIdToPaste() {
      return this.app.client.config.get('copy_component', false)
    },

    /**
     * Return all components from the store
     * @returns {*}
     */
    components() {
      //where("diagram_type", 'component').
      return this.wait("components", Diagram.query().where("module_id", this.module_id).order("diagram_type asc, id asc").get(), [])
    }
  },

  methods: {

    nanoid,

    /**
     * Delete component
     */
    pasteComponent() {
      this.$q.dialog({
        title: 'Paste confirmation',
        message: `Are you sure want to paste component?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {

        await Diagram.remote().subscribe('diagram', {id: this.componentIdToPaste});

        // Duplicate component
        let newComponent = Diagram.duplicate(this.componentIdToPaste, this.app_id, this.module_id);

        // show toast
        if (newComponent) this.$q.notify({
          message: 'Component pasted',
          color: 'positive',
          icon: 'check',
          position: 'top'
        })

        // remove component id from the local storage
        this.app.client.config.set('copy_component', false)

        this.sendMessageToTab('paste-diagram');
      })
    },

    /**
     * Edit component
     * @param component
     */
    editComponentDiagram(component) {
      this.$router.push({name: 'component-diagram', params: {id: component.id}})
    },

    /**
     * Copy component
     * @param component
     */
    copyComponent(component) {

      // store component id in the local storage
      this.app.client.config.set('copy_component', component.id)

      // show toast
      this.$q.notify({
        message: 'Component copied',
        color: 'positive',
        icon: 'check',
        position: 'top'
      });

      this.sendMessageToTab('copy-diagram', component.id);
    },

    /**
     * Open analytics
     * @param component
     */
    openAnalytics(component) {
      this.$router.push({name: 'analytics-paths', params: {id: component.id}})
    },

    /**
     * Delete component
     * @param component
     * @return {Promise<void>}
     */
    async deleteComponent(component) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${component.title} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        Diagram.remote().delete(component.id)
      })
    },

    /**
     * Handle messages received from other tabs.
     * @param {Event} e - The event object containing the message data.
     */
    handleTabMessage(e) {
      const {type, content} = e.data;

      switch (type) {
        case 'copy-diagram':
          this.app.client.config.set('copy_component', content);
          break;
        case 'paste-diagram':
          this.app.client.config.set('copy_component', false);
          break;
      }
    }
  }
}
</script>

<style lang="scss">

@import "@/styles/quasar.variables.scss";

.component-list {
  .component-widget {
    background: $info;
    color: black
  }

  .component-component {
    background: $primary;
    color: white;
  }

  .component-process {
    background: $secondary;
    color: white;
  }

  .component-function {
    background: $accent;
    color: white;
  }
}

</style>
