<template>

  <div>
    <q-input :disable="!!value" :type="multiple ? 'textarea' : ''" :label="title" :hint="hint || undefined" v-model="dynamicString.value" @update:model-value="updateBindings" class="q-mb-md">
      <template v-slot:append>
        <ai-text-generator v-model="dynamicString.value"/>

        <slot name="actions"></slot>
      </template>

    </q-input>

    <div v-if="hasBindings" class="dg-flex dg-direction-column dg-gutter-y-sm">
      <template
        v-for="(bindName, ) in Object.keys(dynamicString?.bindings || [])"
        :key="bindName"
      >
        <binding-card
          :bind-name="bindName"
          :app-id="appId"
          :module-id="moduleId"
          :block-id="blockId"
          :parent-diagram-id="parentDiagramId"
          v-model="dynamicString.bindings[bindName]"
          bg-class="bg-primary text-white"
        />
      </template>

      <template v-if="allowOutgoingBindings">
        <template
          v-for="(bindName, ) in Object.keys(dynamicString?.outgoingBindings || [])"
          :key="bindName"
        >
          <binding-card
            :bind-name="bindName"
            :app-id="appId"
            :module-id="moduleId"
            :block-id="blockId"
            :parent-diagram-id="parentDiagramId"
            v-model="dynamicString.outgoingBindings[bindName]"
            bg-class="bg-accent text-white"
            value-type="setter"
          />
        </template>
      </template>
    </div>
  </div>

</template>

<script>
import AiTextGenerator from "@/components/AI/AiTextGenerator.vue";
import BindingCard from '@/components/DynamicString/BindingCard.vue';

export default {
  name: "DynamicString",
  components: {AiTextGenerator, BindingCard},

  props: {
    title: {},
    marker:{
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: undefined
    },
    appId: {},
    value: {},
    moduleId: {},
    blockId: {},
    parentDiagramId: {},
    modelValue: {
      default: () => ({})
    },
    multiple: {
      default: false
    },
    allowOutgoingBindings: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    dynamicString: {
      value: "",
      bindings: {}
    }
  }),

  computed: {
    /**
     * Computed property to check if there are any bindings.
     * It returns true if there are any bindings or outgoing bindings.
     *
     * @returns {boolean} - True if there are bindings or outgoing bindings, false otherwise.
     */
    hasBindings() {
      return !!Object.keys(this.dynamicString?.bindings || []).length
        || !!Object.keys(this.dynamicString?.outgoingBindings || []).length;
    },
  },

  methods: {
    /**
     * Parses the given string to extract bindings based on the provided regex.
     *
     * @param {string} val - The string to parse for bindings.
     * @param {RegExp} regex - The regular expression to match bindings.
     * @returns {Object} - An object containing the extracted bindings.
     */
    parseBindings(val, regex) {
      const bindings = {}
      let match = regex.exec(val)

      while (match != null) {
        bindings[match[1]] = ""
        match = regex.exec(val)
      }

      return bindings;
    },

    /**
     * Fills the dynamicString object with the provided bindings.
     * It adds new bindings and removes bindings that are no longer present.
     *
     * @param {string} bindingType - The type of bindings to fill (e.g., 'bindings' or 'outgoingBindings').
     * @param {Object} bindings - The bindings to add to the dynamicString object.
     */
    fillBindings(bindingType, bindings) {
      if(!this.dynamicString?.[bindingType]) {
        this.dynamicString[bindingType] = {};
      }

      // Remove bindings that are not in the string anymore
      for (let key in this.dynamicString[bindingType]) {
        if (bindings[key] === undefined) {
          delete this.dynamicString[bindingType][key]
        }
      }

      // Add bindings that are not in the string yet
      for (let key in bindings) {
        if (this.dynamicString?.[bindingType][key] === undefined) {
          this.dynamicString[bindingType][key] = ""
        }
      }
    },

    /**
     * Update bindings
     */
    updateBindings(val) {
      // Parse incoming bindings
      const incomingBindings = this.parseBindings(val?.replace(/{{[^{}]*}}/g, '__IGNORE__'), /{([^}{]+)}/g);
      this.fillBindings('bindings', incomingBindings);

      // Parse outgoing bindings
      const outgoingBindings = this.parseBindings(val, /{{([^{}]+)}}/g);
      this.fillBindings('outgoingBindings', outgoingBindings);
    }
  },

  created() {

    // Init current value
    if(typeof this.modelValue === 'object') this.dynamicString = this.modelValue
    if(typeof this.modelValue === 'string') this.dynamicString.value = this.modelValue

    // Check if value is set - fill it
    if(this.value) this.dynamicString.value = this.value

    // Add watcher
    this.$watch('dynamicString', function (newVal) {

      // Add marker if present
      if(this.marker) {
        newVal.marker = this.marker
      } else  {
        delete newVal.marker
      }

      // Simplify the object if string is static
      if(!newVal.isLocalizable && !Object.keys(newVal.bindings || {}).length && !Object.keys(newVal.outgoingBinding || {}).length) {
        newVal = newVal.value
      }

      this.$emit('update:modelValue', newVal)
    }, {deep: true})

    // Update bindings
    this.updateBindings(this.dynamicString.value)
  },
}

</script>
