<template>
  <ui-prop-select title="Children direction" name="direction" :block="block" :options="directions"/>
  <ui-prop-checkbox title="Extended block" name="extended" :block="block" v-if="!hasStackParent"/>
  <ui-prop-checkbox title="Scrollable" name="scrollable" :block="block"/>

  <template v-if="hasScrollableParent">
    <ui-prop-checkbox title="Sticky" name="sticky" :block="block"/>
    <ui-prop-select v-if="block?.properties?.sticky" title="Sticky position" name="stickyPosition" :block="block" :options="globals.options.sides"/>
  </template>

  <template v-if="scrollable">
    <ui-prop-checkbox title="Hide scrollbar" name="hideScrollbar" :block="block"/>

    <ui-prop-select
      title="Scroll direction"
      name="scrollDirection"
      :block="block"
      :options="globals.options.directions"
      default-value="vertical"
    />
  </template>

  <div class="q-gutter-sm col" v-if="hasStackParent || !block.properties?.extended">
    <ui-prop-dynamic-string property name="width" title="Width" :block="block"/>
    <ui-prop-dynamic-string property name="height" title="Height" :block="block"/>
  </div>

  <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id" />
</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";

export default {
  mixins: [propertiesMixins],
  name: "ContainerPropsCmp",
  props: ['block'],
  components: {UiPropDynamicString, UiPropCheckbox, UiPropSelect, UiDynamicProps},

  computed: {

    /**
     * Get possible directions
     * @return {*}
     */
    directions() {
      return this.globals.options.direction//.filter(o => this.hasContainerParent || o.value !== 'stack');
    },

    /**
     * Check if the block has a scrollable parent
     * @return {*}
     */
    hasScrollableParent() {
      return this.canvas.getNodeParentsById(this.block.id).some(parent => parent?.properties?.scrollable)
    },

    /**
     * Check if the block has stack parent container
     * @return {*}
     */
    hasStackParent() {

      // Temporary fix
      return false;

      // Get all parents
      /*const parents = this.canvas.getNodeParentsById(this.block.id);

      // Get nearest parent
      const parent = parents[parents.length - 2];

      // Check if parent is stack
      return parent?.properties?.direction === 'stack'*/
    },

    /**
     * Check if the block has stack parent container
     * @return {*}
     */
    hasContainerParent() {
      // Get all parents
      const parents = this.canvas.getNodeParentsById(this.block.id);

      // Get nearest parent
      const parent = parents[parents.length - 2];

      // Check if parent is stack
      return parent?.type === 'Container'
    },

    /**
     * Check if the block is scrollable.
     *
     * @return {boolean} True if the block is scrollable, otherwise false.
     */
    scrollable() {
      return this.block.properties?.scrollable === 1;
    },
  }
}

</script>

