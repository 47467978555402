<template>

  <div>
    <q-dialog v-model="widgetArguments">
      <q-card>
        <q-card-section>
          <div class="text-h6">Arguments for the widget</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-markup-table bordered>
            <thead>
            <tr>
              <th>Column</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <q-badge color="blue" label="id"/>
              </td>
              <td>String</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="chat_id"/>
              </td>
              <td>String</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="type"/>
              </td>
              <td>
                <q-badge outline color="primary" label="incoming"/>
                <q-badge class="q-ml-xs" outline color="primary" label="outgoing"/>
              </td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="message_type"/>
              </td>
              <td>
                <q-badge outline color="primary" label="text"/>
                <q-badge class="q-ml-xs" outline color="primary" label="image"/>
              </td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="message"/>
              </td>
              <td>String</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="sent_at"/>
              </td>
              <td>Date and time</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="options"/>
              </td>
              <td>JSON</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="option"/>
              </td>
              <td>String</td>
            </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="customMessageStructure">
      <q-card>
        <q-card-section>
          <div class="text-h6">The structure of a custom message</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-markup-table bordered>
            <thead>
            <tr>
              <th>Column</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <q-badge color="blue" label="role"/>
              </td>
              <td>
                <q-badge outline color="primary" label="assistant"/>
                <q-badge class="q-ml-xs" outline color="primary" label="user"/>
              </td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="type"/>
              </td>
              <td>
                <q-badge outline color="primary" label="text"/>
                <q-badge class="q-ml-xs" outline color="primary" label="image"/>
              </td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="content"/>
              </td>
              <td>
                <q-badge outline color="primary" label="string"/>
                <q-badge class="q-ml-xs" outline color="primary" label="image URL"/>
              </td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="widget"/>
              </td>
              <td>
                Widget component alias
                <div class="text-caption">Optional</div>
              </td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue" label="skip"/>
              </td>
              <td>
                Boolean
                <div class="text-caption">Skip the message from the bot</div>
              </td>
            </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>

        <q-card-section>
          <div class="text-h6">Image example:</div>
          <pre>{{ customMessageExample.image }}</pre>
        </q-card-section>

        <q-card-section>
          <div class="text-h6">Text example:</div>
          <pre>{{ customMessageExample.text }}</pre>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="q-gutter-sm q-mb-sm">
      <q-btn
          dense
          icon="question_mark"
          size="sm"
          label="Widget arguments"
          @click="widgetArguments = true"
      />

      <q-btn
          dense
          icon="question_mark"
          size="sm"
          label="Custom message"
          @click="customMessageStructure = true"
      />
    </div>

    <ui-prop-dynamic-string :block="block" title="Channel" name="channel" untranslatable/>

    <ui-prop-checkbox :block="block" name="connectedToBackend" title="Connect to ABChat Backend" :default-value="0"/>

    <template  v-if="block.properties?.connectedToBackend">
      <ui-prop-checkbox :block="block" name="initChat" title="Init chat on start" :default-value="0"/>
      <ui-prop-dynamic-string :block="block" title="ABChat Bot id" name="abChatBotId" untranslatable/>
      <ui-prop-params-list :block="block" title="ABChat extra arguments" name="chatArgs"/>
    </template>

    <ui-prop-dynamic-string :block="block" title="Bot name" name="botName" hint="Default: bot"/>

    <ui-prop-dynamic-string :block="block" title="User name" name="userName" hint="Default: me"/>

    <q-card class="q-my-sm">
      <q-card-section class="bg-primary text-white">
        Chat diagram
      </q-card-section>

      <q-card-section class="q-pt-none">
        <ui-prop-checkbox :block="block" name="withChatDiagram" title="Enable" :true-value="1" :false-value="0"/>

        <template v-if="block?.properties?.withChatDiagram === 1">
          <ui-prop-select :block="block" name="diagramId" title="Chat diagram" :options="diagramsList"/>

          <ui-prop-arguments v-if="targetDiagramId" :block="block" name="arguments" title="Diagram arguments" :target-block-id="block.id" :diagram-only="true"/>
        </template>
      </q-card-section>
    </q-card>

    <ui-prop-select :block="block" name="dbId" title="Database" :options="dbsList"/>

    <div v-if="block?.properties?.dbId" class="row items-center full-width no-wrap q-gutter-sm">
      <ui-prop-select class="chat-table-select" :block="block" name="tableId" title="Table" :options="tablesList"/>
      <q-btn fab-mini dense icon="question_mark" @click="tableInfo = true"/>
    </div>

    <ui-prop-checkbox :block="block" name="scrollAnimated" title="Animated scroll"/>

    <ui-prop-string
        v-if="block?.properties?.scrollAnimated === 1"
        name="scrollDuration"
        title="Scroll duration (ms)"
        :block="block"
        :default-value="300"
    />

    <div>
      <ui-prop-checkbox :block="block" name="splitMessage" title="Split message by punctuation"/>

      <ui-prop-string
        v-if="block?.properties?.splitMessage === 1"
        name="splitMessageTimeout"
        title="Timeout between messages (s)"
        :block="block"
        :default-value="3"
      />
    </div>


    <ui-prop-value-selector :block="block" name="readonly" title="Read only" only-var-type="bool"
                            :parent-diagram-id="diagram_id"/>

    <q-dialog v-model="tableInfo">
      <q-card>
        <q-card-section>
          <div class="text-h6">The necessary structure of the table</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-markup-table bordered>
            <thead>
            <tr>
              <th>Column</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <q-badge color="blue">id</q-badge>
              </td>
              <td>Autogenerated</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue">chat_id</q-badge>
              </td>
              <td>String</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue">type</q-badge>
              </td>
              <td>String</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue">message</q-badge>
              </td>
              <td>Text</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue">options</q-badge>
              </td>
              <td>JSON</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue">option</q-badge>
              </td>
              <td>String</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue">sent_at</q-badge>
              </td>
              <td>Date and time</td>
            </tr>
            <tr>
              <td>
                <q-badge color="blue">metadata</q-badge>
              </td>
              <td>JSON</td>
            </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-list class="q-my-md">
      <q-expansion-item
        expand-separator
        label="Outgoing message"
        caption="Color settings for outgoing messages"
      >
        <q-card>
          <q-card-section>
            <ui-prop-select name="outgoingTextColor" title="Text color" :block="block" :options="moduleColors"
                            default-value="light"/>

            <ui-prop-select name="outgoingBgColor" title="Background" :block="block" :options="moduleColors"
                            default-value="info"/>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-expansion-item
        expand-separator
        label="Incoming message"
        caption="Color settings for incoming messages"
      >
        <q-card>
          <q-card-section>
            <ui-prop-select name="incomingTextColor" title="Text color" :block="block" :options="moduleColors"
                            default-value="light"/>

            <ui-prop-select name="incomingBgColor" title="Background" :block="block" :options="moduleColors"
                            default-value="success"/>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-expansion-item
        expand-separator
        label="Message field"
        caption="Color settings for the message field"
      >
        <q-card>
          <q-card-section>
            <ui-prop-select name="fieldTextColor" title="Field text color" :block="block" :options="moduleColors"
                            default-value="dark"/>
            <ui-prop-select name="fieldBgColor" title="Field background" :block="block" :options="moduleColors"
                            default-value="tertiary"/>
            <ui-prop-select name="btnTextColor" title="Button color" :block="block" :options="moduleColors"
                            default-value="dark"/>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-expansion-item
        expand-separator
        label="Option button"
        caption="Color settings for the option buttons"
      >
        <q-card>
          <q-card-section>
            <ui-prop-select name="optBtnTextColor" title="Text color" :block="block" :options="moduleColors"
                            default-value="light"/>

            <ui-prop-select name="optBtnBgColor" title="Background" :block="block" :options="moduleColors"
                            default-value="info"/>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>

    <!--ui-prop-custom-list-props name="widgetArgs" title="Widget additional parameters" :block="block">
      <template v-slot:item="data">
        <q-input v-model="data.item.name" label="Parameter name"/>

        <value-selector class="q-mt-md" v-model="data.item.value" :is-array="data.item.isArray" :title="data.item.name"
                        :app-id="product_id" :block-id="block.id" :parent-diagram-id="diagram_id"
                        :module-id="module_id"/>
      </template>
    </ui-prop-custom-list-props!-->

    <ui-prop-value-selector :block="block" name="botIsWriting" title="Opponent is writing" only-var-type="bool"
                            :parent-diagram-id="diagram_id"/>

    <ui-prop-select name="messageSize" title="Message size" :block="block" :options="messageSizes" :default-value="9"/>

    <ui-events-props :block="block" title="Events" name="ui-events" :manual="true"/>

    <ui-dynamic-props :default-value="{height: '500px'}" :block="block" :product_id="product_id"
                      :module_id="module_id"/>
  </div>

</template>

<script>

import {DbModule} from '../../../../../../../../../common/db/DbModule';
import {DbTable} from '../../../../../../../../../common/db/DbTable';
import {Diagram} from '../../../../../../../../../common/db/Diagram';
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import UiDynamicProps from '@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue';
import UiPropString from '@/components/DiagramDesigner/Editor/properties/UiPropString.vue';
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";
import UiPropParamsList from "@/components/DiagramDesigner/Editor/properties/UiPropParamsList.vue";
import UiEventsProps from "@/components/DiagramDesigner/Editor/properties/UiEventsProps.vue";

export default {
  mixins: [propertiesMixins],
  components: {
    UiEventsProps,
    UiPropParamsList,
    UiPropArguments,
    UiPropValueSelector,
    UiPropCheckbox,
    UiPropString,
    UiDynamicProps,
    UiPropSelect,
    UiPropDynamicString,
  },
  props: ['block'],
  inject: ["currentModule"],
  name: "ChatPropsCmp",

  data() {
    return {
      tableInfo: false,
      widgetArguments: false,
      customMessageStructure: false,
      customMessageExample: {
        image: JSON.stringify({
          widget: 'ImageWidget',
          role: 'assistant',
          type: 'image',
          content: 'https://picsum.photos/960/540',
        }, null, 2),
        text: JSON.stringify({
          role: 'assistant',
          type: 'text',
          content: 'Some message',
        }, null, 2),
      },
    };
  },

  computed: {
    /**
     * Get dbs list
     * @return {*}
     */
    dbsList() {
      return this.wait("dbsList", async () => {
        return (await DbModule.query().where({app_id: this.product_id}).get())
            .filter((d) => parseInt(d.module_id) === parseInt(this.module_id) || this.currentModule.modules[d.module_id]?.type === "server")
            .map(d => ({
              value: d.id,
              label: d.name + " (" + this.currentModule.modules[d.module_id]?.name + ")"
            }));
      }, []);
    },

    /**
     * Get tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {
        return (await DbTable.query().where({db_id: this.block?.properties?.dbId || 0}).get()).map(d => ({
          value: d.id,
          label: d.name
        }));
      }, []);
    },

    /**
     * Get diagrams list
     * @return {*}
     */
    diagramsList() {
      return this.wait("diagramsList", async () => {
        return (await Diagram.query().where({module_id: this.module_id, diagram_type: 'process'}).get())
            .map(d => ({
              value: d.id,
              label: d.title
            }));
      }, []);
    },

    /**
     * Computed property that returns the diagramId from the block properties.
     *
     * @return {string|undefined} The ID of the target diagram or undefined if not set.
     */
    targetDiagramId() {
      return this.block.properties?.diagramId;
    },

    messageSizes() {
      return Array.from({length: 12}, (v, k) => k + 1).map(v => ({
        value: v,
        label: v
      }));
    },
  },
}

</script>

<style scoped lang="scss">
.chat-table-select {
  flex-basis: 100%;
}
</style>
