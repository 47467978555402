<template>
  <div class="column full-width full-height media-gallery">

    <q-file v-model="file" ref="uploader" class="hidden" :accept="typesList" @update:model-value="onUpload"/>

    <q-file v-model="files" ref="uploaderMultiply" class="hidden" multiple :accept="typesList" @update:model-value="onUploadMultiply"/>

    <q-dialog ref="editor" class="editor" persistent>
      <media-image-editor :image="selectedImage" @close="$refs.editor.hide()" @save="saveImage($event)" />
    </q-dialog>

    <q-dialog ref="icons" persistent>
      <media-icons @close="$refs.icons.hide()" @save="onIconCreate" />
    </q-dialog>

    <modal-dialog ref="svgEditor" title="Edit icon" persistent max-width="800px">
      <media-icon-editor :svg="selectedSvg.code" @save="saveIcon" />
    </modal-dialog>

    <q-dialog ref="selectItemDialog" persistent>
      <q-card style="width: 500px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none text-h6">{{ embedded ? 'Preview' : 'Select' }} {{ selectedItem.type }}</q-card-section>

        <q-card-section v-if="['sound', 'lottie', 'video', 'image'].includes(selectedItem.type)">
          <q-img
            v-if="selectedItem.type === 'image'"
            :src="`${getResourcePath(selectedItem)}?${upKey}`"
            height="15rem"
            fit="contain"
          />
          <video
            v-else-if="selectedItem.type === 'video'"
            :src="`${getResourcePath(selectedItem)}?${upKey}`"
            muted
            autoplay
            controls
            height="250px"
          />
          <audio
            v-else-if="selectedItem.type === 'sound'"
            :src="`${getResourcePath(selectedItem)}?${upKey}`"
            controls
          />
          <lottie-animation
            v-else-if="selectedItem.type === 'lottie'"
            :animation-link="`${getResourcePath(selectedItem)}?${upKey}`"
            loop
            height="250px"
          />
        </q-card-section>

        <q-card-section v-if="!embedded">Are you sure you want to select this item?</q-card-section>

        <q-card-actions align="right">
          <q-btn flat :label="embedded ? 'OK' : 'Cancel'" color="primary" v-close-popup />
          <q-btn v-if="!embedded" flat label="OK" color="primary" @click="selectItem" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-tabs v-model="source">
      <q-tab v-for="(src) of sourcesList" :key="src" :name="src" :label="src === 'storage' ? 'local' : src"></q-tab>
    </q-tabs>

    <div>
      <q-input class="col" v-model="search" :debounce="1000000" label="Search for image">
        <template v-slot:append>
          <template v-if="source==='media shop'" >
            <q-select label="Color" style="width: 100px" v-model="colorType" emit-value map-options :options="colorTypes"/>
            <q-select label="Shape" style="width: 150px" v-model="styleShape" emit-value map-options :options="shapeOptions"/>
          </template>

          <q-btn flat icon="search">Search</q-btn>

          <template v-if="['storage', 'remote'].includes(source)" >
            <q-btn flat icon="upload" @click="$refs.uploader.pickFiles()">Upload</q-btn>
            <q-btn flat icon="upload" @click="$refs.uploaderMultiply.pickFiles()">Upload multiply</q-btn>

            <q-btn-dropdown v-if="type === 'image'" flat icon="add" dropdown-icon="none" label="Create">
              <q-list>
                <q-item clickable v-close-popup @click="createNew">
                  <q-item-section>
                    <q-item-label>Custom image</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="$refs.icons.show()">
                  <q-item-section>
                    <q-item-label>Icon</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </template>
        </template>
      </q-input>
    </div>

    <div class="col column">
      <component :is="embedded ? 'div' : 'q-scroll-area'" class="col">

        <div class="row wrap q-gutter-md q-ma-sm">

          <template v-if="!filteredItemsList.length">
            Please search for something...
          </template>

          <template v-else>
            <div v-for="(img, k) of filteredItemsList" :key="k" class=" shadow-2 preview column relative-position">
              <div class="col">
                <div class="absolute-top-right q-ma-xs" v-if="['storage', 'remote'].includes(source)">
                  <q-btn class="bg-grey-9" text-color="white" round size="xs" icon="more_vert">
                    <q-menu>
                      <q-list style="min-width: 100px">

                        <q-item v-if="type==='image'" clickable v-close-popup @click="selectedImage=img;$refs.editor.show();">
                          <q-item-section>Edit</q-item-section>
                        </q-item>

                        <q-item v-if="type==='image' && img.source_url.endsWith('.svg')" clickable v-close-popup @click="editIcon(img)">
                          <q-item-section>Icon edit</q-item-section>
                        </q-item>

                        <q-item clickable v-close-popup @click="deleteItem(img)">
                          <q-item-section>Delete</q-item-section>
                        </q-item>

                        <q-item v-if="['storage', 'remote'].includes(source)" clickable v-close-popup @click="moveItem(img)">
                          <q-item-section>To {{ source === 'storage' ? 'remote' : 'local' }}</q-item-section>
                        </q-item>

                        <q-item
                          v-if="['sound', 'lottie', 'video', 'image'].includes(img.type)"
                          clickable
                          v-close-popup
                          :href="getResourcePath(img)+`?v=${upKey}`"
                        >
                          <q-item-section>Download</q-item-section>
                        </q-item>

                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>

                <div
                  v-if="['sound', 'lottie', 'video', 'subtitles', 'fbx'].includes(img.type)"
                  class="col flex flex-center full-height text-grey cursor-pointer"
                  @click="onSelectItem(img)"
                ><q-icon :name="getPreviewIcon(img)" size="5rem" /></div>
                <img v-else :src="getResourcePath(img)+`?v=${upKey}`" @click="onSelectItem(img)"/>
              </div>
              <div class="text-center ellipsis label">
                {{ img.title }}
                <br>
                {{ img.size ? sizeFormatBytes(img.size) : 'n/a' }}
              </div>
            </div>
          </template>

        </div>
      </component>

      <div class="column items-center q-pa-sm">
        <q-pagination
          v-if="total"
          v-model="currentPage"
          direction-links
          :max="Math.ceil(total / limit)"
          :max-pages="5"
        />
      </div>

    </div>
  </div>
</template>

<script>
import {computed} from 'vue';
import {nanoid} from "nanoid";
import axios from "axios";
import {pathHelper} from "@/utils/pathHelper";
import {MediaGallery} from "@/../../common/db/MediaGallery";
import MediaImageEditor from "@/components/MediaGallery/MediaImageEditor.vue";
import LottieAnimation from '@/components/Lottie/LottieAnimation.vue';
import MediaIcons from '@/components/MediaGallery/MediaIcons.vue';
import MediaIconEditor from '@/components/MediaGallery/MediaIconEditor.vue';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';

export default {
  name: "MediaGallery",
  components: {ModalDialog, MediaIconEditor, MediaIcons, LottieAnimation, MediaImageEditor},
  emits: ['selected'],
  provide() {
    return {
      targetSource: computed(() => this.targetSource),
    };
  },
  props: {
    productId: {
      default: null
    },
    moduleId: {
      default: null
    },

    type: {
      default: 'image'
    },

    embedded: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentPage: 1,
    maxPage: 1,
    upKey: 1,
    colorType: "",
    colorTypes: [{value: '', label: 'Any'}, {value: 'black', label: 'B&W'}, {value: 'color', label: 'Color'}, {value: 'gradient', label: 'Gradient'}],
    shapeOptions: [
      {value: "", label: "Any"},
      {value: "outline", label: "Outline"},
      {value: "fill", label: "Fill"},
      {value: "lineal-color", label: "Lineal color"},
      {value: "hand-drawn", label: "Hand drawn"},
    ],
    styleShape: "",
    selectedImage: false,
    total: 0,
    limit: 50,
    search: "",
    file: null,
    files: [],
    updater: 1,
    icon: "",
    items: [],
    source: "storage",
    sourcesList: ['storage', 'remote'],
    selectedItem: null,
    selectedSvg: {
      title: '',
      url: '',
      code: '',
    },
  }),

  /**
   * On create
   */
  created() {
    if (this.type === 'image') this.sourcesList.push('media shop')

    // Reset page on search or color or shape change
    this.$watch('search', () => this.currentPage = 1)
    this.$watch('colorType', () => this.currentPage = 1)
    this.$watch('styleShape', () => this.currentPage = 1)

  },

  computed: {

    /**
     * Get types list
     * @return {string}
     */
    typesList() {
      switch (this.type) {
        case 'image':
          return 'image/png,image/jpeg,image/gif,image/svg+xml'
        case 'pdf':
          return 'application/pdf'
        case 'video':
          return 'video/mp4'
        case 'sound':
          return 'mp3'
        case 'subtitles':
          return 'vtt'
        case 'fbx':
          return 'fbx'
        default:
          return '*'
      }
    },

    /**
     * Get items list from the server
     * @return {*}
     */
    itemsList() {
      return this.wait('itemsList', async () => {

        // Up ket dependency
        console.log(this.upKey)

        // Get controller according to source
        const controller = ['storage', 'remote', 'free gallery'].includes(this.source) ? "media-gallery" : "media-shop"

        // Ask for items
        const {
          items,
          total
        } = await this.app.client.call(controller, 'search', this.search, this.productId, this.moduleId, this.type, this.limit, this.currentPage, this.source, this.updater, {
          colorType: this.colorType,
          styleShape: this.styleShape
        })

        // Calc total pages count
        this.items = items
        this.total = total

        // Return items list
        return items || []
      })
    },

    /**
     * Get filtered items list
     * @return {*}
     */
    filteredItemsList() {
      return this.itemsList.filter(
        (item) => item.type === this.type && item.source_url.startsWith(`${this.source || 'storage'}:`)
      );
    },

    /**
     * Determines the target source for media files.
     * If the current source is 'remote', it returns 'remote'.
     * Otherwise, it returns 'storage', indicating that the local storage is the target.
     *
     * @returns {string} The target source for media files.
     */
    targetSource() {
      return this.source === 'remote' ? this.source : 'storage';
    },

  },

  methods: {

    /**
     * Create new item
     */
    async createNew() {

      // Set file
      if(this.type !== 'image') return alert("Only images can be created")

      // Generate source
      const source_url = `${this.targetSource}:${nanoid(24)}.png`

      // Add to media library
      this.selectedImage = await MediaGallery.remote().save({
        title: "New image",
        source_url,
        type: this.type,
        app_id: this.productId,
        module_id: this.moduleId,
        unique_id: nanoid(10),
        temporary: 1
      })

      // Show editor
      this.$refs.editor.show();
    },

    /**
     * On uploaded
     * @param file
     */
    async onUpload(file) {

      // On clear
      if (!file) {
        this.currentValue = null
        return
      }

      // Upload file and get path
      const res = await this.app.client.upload(`images/${nanoid(10)}.png`, file)

      // Add to media library
      await MediaGallery.remote().save({
        title: file.name,
        source_url: `${this.targetSource}:${res}`,
        type: this.type,
        app_id: this.productId,
        module_id: this.moduleId,
        unique_id: nanoid(10),
        size: file.size,
      })

      // Reset items list
      this.updater++
    },

    /**
     * Checks if a file with the given name exists in the media gallery.
     *
     * @param {string} fileName - The name of the file to check.
     *
     * @returns {Promise<Object|boolean>} - A promise that resolves to the media item if it exists and its source matches the target source. If no such media item exists or the source does not match, the promise resolves to false.
     *
     * @throws {Error} If the call to the 'media-gallery' service fails, an error is thrown.
     */
    async checkIfExists(fileName) {
      return await this.app.client.call(
        'media-gallery',
        'checkIfExists',

        this.moduleId,
        this.type,
        this.targetSource,
        fileName,
      );
    },

    /**
     * Confirms the upload of a file that already exists in the media library.
     * It shows a dialog to the user asking if they want to skip the file or rewrite it.
     * If the user chooses to rewrite the file, it reads the file as a data URL and splits the result to get the base64 string of the file.
     * It then calls the 'updateFile' method of the 'media-gallery' service with the source URL of the media item, the name of the file, the base64 string of the file, and 0 as arguments.
     * After the file is uploaded, it increments the 'upKey' property and resolves the promise.
     * If the user chooses to skip the file or dismisses the dialog, it simply resolves the promise.
     *
     * @param {File} file - The file to upload.
     * @param {Object} media - The media item that matches the file.
     * @param {string} media.source_url - The source URL of the media item.
     *
     * @returns {Promise<void>} A promise that resolves when the user makes a choice in the dialog.
     *
     * @throws {Error} If the call to the 'media-gallery' service fails, an error is thrown.
     */
    async confirmUpload(file, media) {
      return new Promise((resolve) => {
        this.$q.dialog({
          title: `File already exists`,
          message: `File "${file.name}" already exists in the media library`,
          cancel: 'Skip',
          ok: 'Rewrite',
          persistent: true,
        }).onOk(async () => {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64String = reader.result.split(',')[1];

            await this.app.client.call('media-gallery', 'updateFile', media.source_url, file.name, base64String, 0)

            this.upKey++;

            resolve();
          };
          reader.readAsDataURL(file);
        }).onDismiss(() => {
          resolve();
        }).onCancel(() => {
          resolve();
        });
      });
    },

    /**
     * Handles the upload of multiple files.
     * It shows a dialog to the user indicating that the files are being uploaded.
     * For each file, it checks if a media item with the same name already exists in the media gallery.
     * If such a media item exists, it asks the user to confirm the upload of the file.
     * If the user confirms the upload, it reads the file as a data URL and splits the result to get the base64 string of the file.
     * It then calls the 'updateFile' method of the 'media-gallery' service with the source URL of the media item, the name of the file, the base64 string of the file, and 0 as arguments.
     * After the file is uploaded, it increments the 'upKey' property.
     * If no such media item exists, it simply uploads the file.
     * If an error occurs during the upload, it shows a notification to the user with the error message.
     * After all files have been processed, it hides the dialog.
     *
     * @param {Array<File>} files - The files to upload.
     *
     * @throws {Error} If the call to the 'media-gallery' service fails, an error is thrown.
     */
    async onUploadMultiply(files) {
      const dialog = this.$q.dialog({
        message: 'Uploading files',
        progress: true,
        persistent: true,
        ok: false
      });

      try {
        for (const file of files) {
          const media = await this.checkIfExists(file.name);

          if (media) {
            await this.confirmUpload(file, media);
          } else {
            await this.onUpload(file);
          }
        }
      } catch (e) {
        this.$q.notify({
          message: e.message || e || 'An error occurred',
          color: 'negative',
          icon: 'error',
          position: 'top'
        });
      } finally {
        dialog.hide();
      }
    },

    /**
     * Return image path
     * @return {string}
     * @param itm
     */
    getResourcePath(itm) {
      return ['image', 'video', 'sound', 'lottie'].includes(itm.type) ? pathHelper.assetPath(itm.source_url) : require("@/assets/plugs/default-image.png")
    },

    /**
     * This method is used to get the appropriate icon for a given media type.
     * It takes an image object as an argument and returns a string representing the icon name.
     *
     * @param {Object} img - The image object.
     * @param {string} img.type - The type of the image. It can be 'sound', 'lottie', 'video', 'subtitles', or any other type.
     *
     * @returns {string} The name of the icon corresponding to the image type.
     * 'audio_file' for 'sound', 'animation' for 'lottie', 'video_file' for 'video', 'subtitles' for 'subtitles', and 'draft' for any other type.
     */
    getPreviewIcon(img) {
      switch (img.type) {
        case 'sound':
          return 'audio_file';
        case 'lottie':
          return 'animation';
        case 'video':
          return 'video_file';
        case 'subtitles':
          return 'subtitles';
        case 'fbx':
          return '3d_rotation';
        default:
          return 'draft';
      }
    },

    /**
     * Save image
     * @param image
     * @param data
     * @return {Promise<void>}
     */
    async saveImage({url, title, image, psd}) {

      // Upload file and get path
      if(await this.app.client.call('media-gallery', 'updateFile', url, title, image, psd)) {
        // Show success
        this.$q.notify({
          message: 'Image saved',
          color: 'positive',
          icon: 'done',
          position: 'top'
        })

        // Update images
        this.upKey++;
      }
    },

    /**
     * Delete item
     * @param img
     */
    deleteItem(img) {
      // Ask for confirmation
      this.$q.dialog({
        title: 'Delete image',
        message: 'Are you sure you want to delete this image?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        await MediaGallery.remote().call('media-gallery', 'deleteFile', img.source_url)
        this.upKey++
      })
    },

    /**
     * Move item
     * @param img
     */
    moveItem(img) {
      // Ask for confirmation
      this.$q.dialog({
        title: 'Move item',
        message: `Are you sure you want to move this item to ${this.source === 'storage' ? 'remote' : 'local'}?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        const [, src] = img.source_url.split(':');
        const source = this.targetSource === 'storage' ? 'remote' : 'storage';

        img.source_url = `${source}:${src}`;

        await MediaGallery.remote().save(img);
      })
    },

    /**
     * Select item
     * @param img
     */
    async onSelectItem(img) {
      this.selectedItem = img;

      this.$refs.selectItemDialog.show();
    },

    /**
     * Select item
     */
    async selectItem() {
      if (this.embedded) {
        return;
      }

      // Check the source of image
      const externalSource = this.selectedItem.source_url.indexOf("http") === 0

      // Download image first in case of external source
      if(externalSource) {

        // Show loader
        this.$q.loading.show({delay: 500})

        // Download image from external resource and upload to local storage
        const image = await axios.get(this.selectedItem.source_url, {responseType: 'arraybuffer'})

        // Check if image downloaded
        if(image.status !== 200 || !image.data) throw `Can't download image: ${this.selectedItem.source_url}`

        const blob = new Blob([image.data], { type: 'application/octet-stream' });
        const file = new File([blob], this.selectedItem.title+".png");

        // Upload file and get path
        const res = await this.app.client.upload(`images/${nanoid(10)}.png`, file)
        if(!res) throw `Can't upload image ${this.selectedItem.source_url} to local storage`

        // Construct source url
        const source_url = `${this.targetSource}:${res}`;
        console.log("!#@!#!", source_url)

        // Add to media library
        this.selectedItem = await MediaGallery.remote().save({
          title: this.selectedItem.title,
          type: this.type,
          source_url,
          app_id: this.productId,
          module_id: this.moduleId,
          unique_id: nanoid(10)
        })

        // Show loader
        this.$q.loading.hide();
      }

      this.$refs.selectItemDialog.hide();

      // Emit selection
      this.$emit('selected', this.selectedItem)
    },

    /**
     * Converts the size from bytes to a more readable format.
     *
     * @param {number} bytes - The size in bytes.
     * @returns {string} The size converted to the most appropriate unit (Bytes, KB, MB, GB, TB, PB, EB, ZB, YB).
     */
    sizeFormatBytes(bytes) {
      if (bytes === 0) return '0 Bytes';
      const bytesInKilobyte = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(bytesInKilobyte));

      return parseFloat((bytes / Math.pow(bytesInKilobyte, i)).toFixed(2)) + ' ' + sizes[i];
    },

    /**
     * Edits the selected SVG icon.
     * Fetches the SVG data from the server and sets it to the selectedSvg object.
     * Shows the SVG editor dialog.
     *
     * @param {Object} img - The image object containing the SVG icon.
     */
    async editIcon(img) {
      try {
        const {data} = await axios.get(this.getResourcePath(img));

        this.selectedSvg.code = data;
        this.selectedSvg.title = img.title;
        this.selectedSvg.url = img.source_url;

        this.$refs.svgEditor.show();
      } catch (e) {
        console.error('Error loading icon', e);

        this.$q.notify({
          message: 'Error loading icon',
          color: 'negative',
          icon: 'error',
          position: 'top'
        });
      }
    },

    /**
     * Saves the edited SVG icon.
     * Calls the saveImage method to save the SVG data.
     * Hides the SVG editor dialog.
     *
     * @param {string} base64Image - The base64 encoded SVG image data.
     */
    async saveIcon(base64Image) {
      try {
        await this.saveImage({
          url: this.selectedSvg.url,
          title: this.selectedSvg.title,
          image: base64Image,
        });

        this.$refs.svgEditor.hide();
      } catch (e) {
        console.error('Error saving icon', e);

        this.$q.notify({
          message: 'Error saving icon',
          color: 'negative',
          icon: 'error',
          position: 'top'
        });
      }
    },

    onIconCreate() {
      this.$refs.icons.hide();

      this.upKey++;
    },
  }
}

</script>

<style lang="scss">

.media-gallery {

  .preview {
    width:150px;
    height: 150px;
    align-content: center;
    overflow: hidden;
    background: white;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }

    .label {
      background: #eee;
      width: 150px;
      padding: 5px;
      font-size: 0.8em;
    }
  }
}

</style>
