<template>

  <ui-prop-dynamic-string name="label" title="Label" :block="block"/>
  <ui-prop-dynamic-string name="hint" title="Hint" :block="block"/>
  <ui-prop-select name="behavior" title="Behavior" :block="block" :options="behavior" default-value="default"/>
  <ui-prop-value-selector :block="block" title="Options list" name="options"  only-var-type="options" :is-array="true" :parent-diagram-id="diagram_id"/>

  <ui-prop-select name="manualOptionsState" title="Manual or extra options" :block="block" :options="manualSelectOptions" default-value="none"/>
  <ui-data-props v-if="this.block?.properties?.manualOptionsState !== 'none'" title="Manual or extra options" name="manualOptions" :default-schema="defaultSchema" :block="block"/>

  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>
  <ui-prop-checkbox name="multiple" title="Multiple value" :block="block" :true-value="1" :false-value="0"/>

  <ui-prop-select name="controlTextColor" title="Text color" :block="block" :options="moduleColors" default-value="primary"/>
  <ui-prop-select name="controlFocusColor" title="Focus color" :block="block" :options="moduleColors" default-value="info"/>

  <ui-data-props
    name="validationRules"
    title="Validation rules"
    :default-schema="validationSchema"
    :block="block"
    :key="validationRulesKey"
  />

  <ui-dynamic-props :default-value="{}" :block="block" />
</template>

<script>
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiDataProps from "@/components/DiagramDesigner/Editor/properties/UiDataProps.vue";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import validatableFormFieldMixin
  from '@/components/DiagramDesigner/Editor/components/UI/Form/validatableFormFieldMixin';
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';

export default {
  mixins: [propertiesMixins, validatableFormFieldMixin],
  name: "SelectPropsCmp",
  props: ['block'],
  components: {
    UiPropDynamicString,
    UiPropSelect, UiDataProps, UiPropCheckbox, UiDynamicProps, UiPropValueSelector},
  data() {
    return {
      defaultSchema: [
        {
          name: "value",
          type: "string"
        },
        {
          name: "label",
          type: "string"
        },
      ],

      behavior: [
        {label: "Default", value: "default"},
        {label: "Menu", value: "menu"},
        {label: "Dialog", value: "dialog"},
      ],

      manualSelectOptions: [
        {label: "None", value: "none"},
        {label: "Before the main options", value: "before"},
        {label: "After the main options", value: "after"},
      ],
    };
  },
}

</script>

