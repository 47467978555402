<template>

  <div>
    <q-form ref="moduleForm" @submit="onSaveModule" class="q-gutter-md">

      <q-input
          v-model="mutate(editModule).name"
          label="Module name *"
          hint="title of module"
          lazy-rules
          :rules="[ val => val && val.length > 0 || 'Please type something']"
      />

      <div class="row q-col-gutter-x-md full-width">
        <div class="col">
          <div class="q-pa-sm rounded-borders bg-grey-2">
            <q-input
              v-model="mutate(editModule).endpoint.stage"
              label="Stage endpoint"
              hint="subdomain of module, empty for root"
              lazy-rules
            />

            <template v-if="mutate(editModule).type === 'server'">
              <q-input
                v-model="mutate(editModule).endpoint.cdn_stage"
                label="Stage CDN endpoint"
                hint="subdomain of module, empty for root"
                lazy-rules
              />

              <q-checkbox
                v-model="mutate(editModule).endpoint.healthCheckStage"
                :true-value="1"
                :false-value="0"
                label="Health check"
              />
            </template>
          </div>
        </div>

        <div class="col">
          <div class="q-pa-sm rounded-borders bg-grey-2">
            <q-input
                class="col"
                v-model="mutate(editModule).endpoint.release"
                label="Release endpoint"
                hint="subdomain of module, empty for root"
                lazy-rules
            />

            <template v-if="mutate(editModule).type === 'server'">
              <q-input
                class="col"
                v-model="mutate(editModule).endpoint.cdn_release"
                label="Release CDN endpoint"
                hint="subdomain of module, empty for root"
                lazy-rules
              />

              <q-checkbox
                v-model="mutate(editModule).endpoint.healthCheckRelease"
                :true-value="1"
                :false-value="0"
                label="Health check"
              />
            </template>
          </div>
        </div>
      </div>

      <q-select
          class="col"
          v-model="mutate(editModule).type"
          label="Module type"
          map-options
          emit-value
          :options="globals.options.module_types"
      />

      <q-select
          class="col"
          v-model="mutate(editModule).language"
          label="Module type"
          map-options
          emit-value
          :options="languageList"
      />

      <q-select
          class="col"
          v-model="mutate(editModule).start_diagram_id"
          label="Module diagram"
          map-options
          emit-value
          :options="componentList"
      />

      <q-select
          v-if="!['server', 'chat-bot'].includes(mutate(editModule).type)"
          class="col"
          v-model="mutate(editModule).cdn_server"
          label="CDN Server"
          map-options
          emit-value
          :options="serverModulesList"
      />

      <div>
        <q-checkbox
          v-if="mutate(editModule).type === 'server'"
          v-model="mutate(editModule).endpoint.hasWebSocket"
          :true-value="1"
          :false-value="0"
          label="There is a WebSocket"
        />
      </div>

      <q-checkbox v-model="mutate(editModule).persistent_storage" :true-value="1" :false-value="0" label="Persistent db"/>

      <q-input
        v-if="mutate(editModule).type === 'web'"
        v-model.number="mutate(editModule).settings.styles.page.maxWidth"
        label="Maximum width, px"
        hint="The maximum width of the application page"
      />

      <div>
        <q-btn label="Save" type="submit" color="primary"/>
      </div>
    </q-form>

  </div>

</template>

<script>

import {AppModule} from "@/../../common/db/AppModule";
import {Diagram} from "@/../../common/db/Diagram";
import {AppStyle} from "../../../../../common/db/AppStyle";
import {nanoid} from "nanoid";

export default {
  name: "ModuleForm",
  props: ['editModule'],
  emits: ['saved'],
  created() {
    this.$watch("editModule", () => {
      if(typeof this.editModule?.endpoint !== "object") {
        this.mutate(this.editModule).endpoint = {};

        // Set default settings
        this.mutate(this.editModule).settings = {
          styles: {
            colors: this.globals.options.colors
              .filter((color) => !!color.value)
              .map((color) => ({
                label: color.label,
                value: color.value,
              }))
          },
        };
      }

      this.mutate(this.editModule).settings = this.mutate(this.editModule).settings || {};
      this.mutate(this.editModule).settings.styles = this.mutate(this.editModule).settings.styles || {};
      this.mutate(this.editModule).settings.styles.page = this.mutate(this.editModule).settings.styles.page || {};
    }, {deep: true, immediate: true});
  },
  computed: {


    /**
     * Language list
     */
    languageList() {
      return this.globals.options.module_languages?.filter(el => el.types?.includes(this.editModule.type) )
    },

    /**
     * Component list
     * @return {*}
     */
    componentList() {
      return this.wait("components", async () => {
        return (await Diagram.query().where({"module_id": this.editModule.id, diagram_type: "component"}).get()).
        map(el => ({
          label: el.title,
          value: el.id
        }))
      }, [])
    },

    /**
     * Server modules list
     * @return {*}
     */
    serverModulesList() {
      return this.wait("serverModulesList", async () => {
        return [
          { label: 'System CDN', value: 0 },
          ...(await AppModule.query().where({"app_id": this.editModule.app_id, type: 'server'}).get())
            .map(el => ({ label: el.name, value: el.id }))
        ]
      }, [])
    },
  },
  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveModule() {

      // Check if module is new
      const isNew = !this.editModule.id

      // Update
      const savedModule = await AppModule.remote().save(this.editModule)

      // Add styles for module
      if(isNew) {
        await AppStyle.remote().save({
          app_id: this.editModule.app_id,
          module_id: savedModule.id,
          unique_id: nanoid(10),
          title: "Default",
          source: {
            colors: this.globals.options.colors.filter((v) => !!v.value)
              .reduce((res, v) => {
                res[v.value] = {
                  foreground: v.foreground || '#fff',
                  background: v.background || '#000',
                };

                return res;
              }, {}),
          }
        });
      }

      // Hide dialog
      this.$emit('saved')
    }
  }
}

</script>
