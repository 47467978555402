import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiAnimation from "@/components/DiagramDesigner/Editor/properties/UiAnimation";
import UiPropPaddings from "@/components/DiagramDesigner/Editor/properties/UiPropPaddings";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import {constants} from "@/../../common/constants/constants";
import UiPropBorder from "@/components/DiagramDesigner/Editor/properties/UiPropBorder";
import UiPropVisibility from "@/components/DiagramDesigner/Editor/properties/UiPropVisibility.vue";
import UiEventsProps from "@/components/DiagramDesigner/Editor/properties/UiEventsProps.vue";
import UiPropMargins from "@/components/DiagramDesigner/Editor/properties/UiPropMargins.vue";
import UiPropBgColor from "@/components/DiagramDesigner/Editor/properties/UiPropBgColor.vue";
import UiPropPanelStyle from "@/components/DiagramDesigner/Editor/properties/UiPropPanelStyle.vue";
import UiKeyPressProps from "@/components/DiagramDesigner/Editor/properties/UiKeyPressProps.vue";
import UiPropTextShadow from "@/components/DiagramDesigner/Editor/properties/UiPropTextShadow.vue";
import UiPropMaskImage from '@/components/DiagramDesigner/Editor/properties/UiPropMaskImage.vue';
import UiPropBoxShadow from '@/components/DiagramDesigner/Editor/properties/UiPropBoxShadow.vue';
import UiPropColorsSelect from '@/components/DiagramDesigner/Editor/properties/UiPropColorsSelect.vue';
import UiPropGutters from '@/components/DiagramDesigner/Editor/properties/UiPropGutters.vue';
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";

export default {
    /*visibility: {
        label: () => "Block visibility",
        description: "Visibility condition",
        forbidden: ['Fragment'],
        component: UiPropString
    },*/
    width: {
        label: () => "Width",
        description: "Component width",
        forbidden: ['Fragment', 'Container', 'Image', 'Lottie', 'Widget', 'AdBanner', 'Icon', 'Carousel', 'Teleport', 'LinearProgress', 'CircularProgress', 'ChartView'],
        component: UiPropDynamicString
    },
    height: {
        label: () => "Height",
        description: "Component height",
        forbidden: ['Fragment', 'Container', 'Image', 'Lottie', 'Widget', 'AdBanner', 'Icon', 'Carousel', 'Teleport', 'LinearProgress', 'CircularProgress', 'ChartView'],
        component: UiPropDynamicString
    },

    minWidth: {
        label: () => "Minimal width",
        description: "Component minimal width, px",
        forbidden: ['Fragment'],
        component: UiPropString
    },
    minHeight: {
        label: () => "Minimal height",
        description: "Component minimal height, px",
        forbidden: ['Fragment'],
        component: UiPropString
    },

    maxWidth: {
        label: () => "Maximum width",
        description: "Component maximal width, px",
        forbidden: ['Fragment'],
        component: UiPropString
    },
    maxHeight: {
        label: () => "Maximum height",
        description: "Component maximal height, px",
        forbidden: ['Fragment'],
        component: UiPropString
    },
    animation: {
        label: () => "Animation",
        description: "Animation panel",
        forbidden: ['Fragment'],
        component: UiAnimation
    },
    background: {
        label: (data) => `Background: ${data ? data.type : "-"}`,
        description: "Background settings",
        forbidden: ['Fragment'],
        component: UiPropBgColor
    },
    extended: {
        label: (data) => `Expanded: ${data ? data : ""}`,
        description: "Expanded block",
        options: constants.options.yesnoBin,
        forbidden: ['Fragment'],
        component: UiPropSelect
    },
    movableChildren: {
        label: (data) => `Movable children: ${data ? data : ""}`,
        description: "Movable children",
        options: constants.options.yesno,
        forbidden: ['Fragment'],
        component: UiPropSelect
    },
    /*flexDirection: {
        label: (data) => `Direction: ${data ? data : ""}`,
        defaultValue: "row",
        description: "Block direction",
        options: constants.options.direction,
        forbidden: ['Fragment'],
        component: UiPropSelect
    },*/
    flexWrap: {
        label: (data) => `Wrap: ${data || ""}`,
        defaultValue: "wrap",
        description: "Wrap",
        options: constants.options.wrap,
        forbidden: ['Fragment'],
        component: UiPropSelect
    },
    /*fontSize: {
        label: (data) => `Font size: ${data ? data : ""}`,
        description: "Text font size",
        forbidden: ['Fragment'],
        component: UiPropString
    },*/
    lineHeight: {
        label: (data) => `Line height: ${data || ""}`,
        description: "Line height",
        options: constants.options.lineHeight,
        //forbidden: ['Text', 'Flex'],
        allowed: ['Text', 'Container', 'RouletteSelector'],
        component: UiPropSelect
    },
    textShadow: {
        label: (data) => `Text shadow: ${data || ""}`,
        description: "Text shadow",
        allowed: ['Text', 'Container'],
        component: UiPropTextShadow
    },
    boxShadow: {
        label: (data) => `Box shadow: ${data || ""}`,
        description: "Box shadow",
        forbidden: ['Fragment', 'Text'],
        component: UiPropBoxShadow
    },
    backgroundColor: {
        label: (data) => `Background color: ${data ? data : ""}`,
        description: "Background color",
        forbidden: [],
        component: UiPropColorsSelect,
    },
    textColor: {
        label: (data) => `Text color: ${data ? data : ""}`,
        description: "Text color",
        forbidden: ['Fragment'],
        component: UiPropColorsSelect,
    },
    textStyle: {
        label: (data) => `Text style: ${data ? data : ""}`,
        description: "Text style",
        forbidden: ['Fragment'],
        options: constants.options.textStyles,
        component: UiPropSelect
    },
    textWeight: {
        label: (data) => `Text color: ${data ? data : ""}`,
        description: "Text weight",
        options: constants.options.textWeight,
        allowed: ['Text', 'Button'],
        forbidden: ['Fragment'],
        component: UiPropSelect
    },
    textDecoration: {
        label: (data) => `Text decoration: ${data ? data : ""}`,
        description: "Text decoration",
        options: constants.options.textDecoration,
        allowed: ['Text', 'Flex'],
        forbidden: ['Fragment'],
        component: UiPropSelect
    },
    textAlign: {
        label: (data) => `Text align: ${data ? data : ""}`,
        description: "Text align",
        options: constants.options.textAlign,
        allowed: ['Text', 'RichText', 'Container'],
        forbidden: ['Fragment'],
        component: UiPropSelect
    },
    contentAlign: {
        label: (data) => `Content align: ${data ? data : ""}`,
        description: "Block content align",
        options: constants.options.contentAlign,
        forbidden: [],
        allowed: ['Container'],
        component: UiPropSelect
    },
    isContainer: {
        label: (data) => `Block is container: ${data ? data : ""}`,
        description: "Block can contain children",
        options: constants.options.yesno,
        allowed: ['Flex'],
        component: UiPropSelect
    },
    ellipsis: {
        label: (data) => `Ellipsis overflow: ${data ? data : ""} lines`,
        description: "Ellipsis text overflow",
        options: constants.options.ellipsisOptions,
        allowed: ['Flex', 'Text'],
        component: UiPropSelect
    },
    itemsAlign: {
        label: (data) => `Block items align: ${data ? data : ""}`,
        description: "Items align",
        options: constants.options.contentAlign,
        forbidden: ['Fragment'],
        allowed: ['Container'],
        component: UiPropSelect
    },

    padding: {
        label: (data) => `Block padding: ${data ? data : ""}`,
        description: "Block paddings",
        forbidden: [],
        component: UiPropPaddings
    },

    visibility: {
        label: (data) => `Visibility: ${data ? data : ""}`,
        description: "Block visibility",
        forbidden: ['Fragment'],
        component: UiPropVisibility
    },

    margin: {
        label: (data) => `Block margin: ${data ? data : ""}`,
        description: "Block margins",
        forbidden: ['Fragment'],
        component: UiPropMargins
    },/**/

    border: {
        label: (data) => `border: ${data ? `${data.weight}, ${data.color}, ${data.radius}` : ""}`,
        description: "Block border",
        forbidden: ['Fragment'],
        component: UiPropBorder
    },

    events: {
        label: (data) => `events: ${data ? data : ""}`,
        description: "Block events",
        forbidden: ['Fragment'],
        allowed: ['Container', 'Image', 'Lottie', 'Icon'],
        component: UiEventsProps
    },

    keypress: {
        label: (data) => `Keys: ${data ? data : ""}`,
        description: "Key press events",
        forbidden: ['Fragment'],
        allowed: ['TextInput', 'ContentEditor'],
        component: UiKeyPressProps
    },

    panelStyle: {
        label: (data) => `Dynamic panel style: ${data ? data : ""}`,
        description: "Dynamic panel style",
        forbidden: ['Fragment'],
        component: UiPropPanelStyle
    },
    blur: {
        label: (data) => `Blur: ${data || ""}`,
        description: "Blur",
        options: constants.options.sizes,
        forbidden: [],
        component: UiPropSelect,
    },
    maskImage: {
        label: (data) => `Mask image: ${data ? data.type : "-"}`,
        description: "Mask image",
        forbidden: ['Fragment'],
        component: UiPropMaskImage,
    },
    disableEvents: {
        label: (data) => `Disable events: ${data ? data : "-"}`,
        description: "Disable events",
        forbidden: [],
        options: constants.options.yesnoBin,
        component: UiPropSelect
    },
    stopPropagation: {
        label: () => 'Stop propagation',
        description: "Stop propagation (stops event bubbling)",
        options: constants.options.yesnoBin,
        allowed: ['Button'],
        component: UiPropSelect
    },

    gutter: {
        label: (data) => `Grid gutter: ${data ? data : ""}`,
        description: "Grid gutter",
        forbidden: ['ContentSwiperStack'],
        component: UiPropGutters,
    },
    disabledState: {
        label: (data) => `Disabled state: ${data || ""}`,
        description: "Disabled state",
        allowed: ['TextInput', 'FileUploader', 'SelectBox', 'Rating', 'Slider', 'DatePicker', 'Button'],
        component: UiPropValueSelector,
    },

}
