<template>
  <div>
    <dynamic-string
      :multiple="multiple"
      :marker="property ? 'dyn-prop' : undefined"
      v-model="dynamicString"
      :app-id="product_id"
      :module-id="module_id"
      :hint="hint"
      :block-id="block.id"
      :title="title || label"
      :parent-diagram-id="diagram_id"
      :allow-outgoing-bindings="allowOutgoingBindings"
    >
      <template v-if="!untranslatable && (app.currentMediaDesigner?.appLocales || []).length" #actions>
        <q-checkbox
          v-model="dynamicString.isLocalizable"
          :false-value="false"
          true-value="plain"
          color="positive"
          checked-icon="public"
          unchecked-icon="public_off"
          title="Translatable"
        />

        <localization-editor
          v-if="isLocalizable"
          v-model="dynamicString.value"
          :multiple="multiple"
          :alias="dynamicString.localeAlias"
        />
      </template>
    </dynamic-string>
  </div>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import DynamicString from "@/components/DynamicString/DynamicString.vue";
import LocalizationEditor from '@/components/Localizations/LocalizationEditor.vue';
import {nanoid} from 'nanoid';

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropDynamicString",

  components: {LocalizationEditor, DynamicString},

  props: {
    property:{
      type: Boolean,
      default: false
    },
    multiple: {
      default: false
    },
    defaultValue: {
      type: String,
      radius: 0
    },
    label: {
      type: String,
      default: ""
    },
    untranslatable: {
      type: Boolean,
      default: false,
      required: false,
    },
    storeValueOnBackend: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowOutgoingBindings: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      dynamicString: {
        value: "",
        isLocalizable: false,
        storeValueOnBackend: this.storeValueOnBackend,
        bindings: {}
      }
    };
  },

  computed: {
    /**
     * Check if the current value is localizable
     * @returns {boolean}
     */
    isLocalizable() {
      return this.dynamicString?.isLocalizable || false;
    },
  },

  methods: {},

  created() {

    // Init current value
    if(typeof this.currentValue === 'object') this.dynamicString = this.currentValue;
    if(typeof this.currentValue === 'string') this.dynamicString.value = this.currentValue;

    if (this.storeValueOnBackend && !this.dynamicString.storeValueOnBackend) {
      this.dynamicString.storeValueOnBackend = true;
    }

    // Set isLocalizable property
    if (!this.untranslatable) {
      this.dynamicString.isLocalizable = this.currentValue?.isLocalizable || false;
    }

    // If the value is localizable and has a localeAlias, set the value to the localized value
    if (!this.untranslatable && this.isLocalizable && this.dynamicString.localeAlias) {
      const mainLocale = this.app.currentMediaDesigner?.mainLocale;

      const localizeValue = this.app.currentMediaDesigner?.localizations[mainLocale]?.[this.dynamicString.localeAlias] || "";

      if (localizeValue) {
        this.dynamicString.value = localizeValue;
      }
    }

    // Add watcher
    this.$watch('dynamicString', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  watch: {
    /**
     * Watch for changes in the current value and set the localeAlias
     */
    isLocalizable() {
      if (this.isLocalizable && !this.dynamicString.localeAlias) {
        this.dynamicString.localeAlias = nanoid(10);
      }
    },
  },
}

</script>
