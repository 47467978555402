<template>

  <ui-prop-dynamic-string name="label" title="Input label" :block="block" />
  <ui-prop-dynamic-string name="hint" title="Input hint" :block="block"/>
  <ui-prop-dynamic-string name="placeholder" title="Placeholder" :block="block"/>
  <ui-prop-checkbox v-if="!block.properties?.isPassword" name="isMultyLine" title="Multiply lines" :block="block"/>
  <br/>
  <ui-prop-checkbox v-if="!block.properties?.isMultyLine" name="isPassword" title="Password input" :block="block"/>
  <br/>
  <ui-prop-checkbox name="standout" title="Hide border" :block="block" :default-value="0"/>
  <br/>
  <ui-prop-checkbox name="autofocus" title="Autofocus" :block="block" :default-value="0"/>

  <ui-prop-string name="debounce" title="Debounce (ms)" :block="block"/>
  <ui-prop-dynamic-string :block="block" title="Max length" name="maxLength" untranslatable />

  <ui-prop-select name="controlTextColor" title="Text color" :block="block" :options="moduleColors" default-value="primary"/>
  <ui-prop-select name="controlUnfocusedColor" title="Unfocused color" :block="block" :options="moduleColors" default-value="primary"/>
  <ui-prop-select name="controlFocusColor" title="Focus color" :block="block" :options="moduleColors" default-value="primary"/>

  <ui-prop-checkbox v-if="block.properties?.isMultyLine" name="isAutoGrow" title="Auto grow" :block="block"/>
  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>

  <ui-data-props
    name="validationRules"
    title="Validation rules"
    :default-schema="validationSchema"
    :block="block"
    :key="validationRulesKey"
  />

  <ui-dynamic-props :default-value="{}" :block="block" />

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import UiDataProps from '@/components/DiagramDesigner/Editor/properties/UiDataProps.vue';
import validatableFormFieldMixin
  from '@/components/DiagramDesigner/Editor/components/UI/Form/validatableFormFieldMixin';

export default {
  mixins: [propertiesMixins, validatableFormFieldMixin],
  name: "InputPropsCmp",
  props: ['block'],
  components: {
    UiDataProps,
    UiPropDynamicString, UiPropSelect, UiPropCheckbox, UiDynamicProps, UiPropValueSelector, UiPropString},
}

</script>

