<template>

  <div v-if="isReady && componentsList && source" ref="diagramEditor" class="diagram-designer dg-media" :style="cssProps">

    <q-dialog ref="buildApp">
      <q-card class="full-width">
        <q-card-section class="row bg-primary text-white">
          <div class="text-subtitle1">Build app</div>
          <q-space/>
          <q-btn flat icon="close" @click="$refs.buildApp.hide();"/>
        </q-card-section>
        <q-card-section>
          <build-app :mode="buildMode" :module-id="currentModule?.currentModule?.id" @close="$refs.buildApp.hide();"/>
        </q-card-section>

      </q-card>
    </q-dialog>

    <ab-flow-designer
      ref="editor"
      class="full-height full-width"
      :root="source"
      :componentsList="componentsList"
      :selectable-root="true"
      :has-paste="hasPaste"
      :canvas_size="{width:1000000, height: 1000000}"
      :canvas_position="canvas_position"
      :product_id="product_id"
      @selected="onSelect"
      @add-block="addBlock"
      @delete-block="deleteBlockWithPrompt"
      @update-block="updateBlock"
      @move-block-parent="moveToParent"
      @wrap-block="wrapBlock"
      @add-link="addLink"
      @set-property="setProperty"
      @canvas-moved="canvasMoved"
      @copy-block="copyBlock"
      @paste-block="pasteBlock"
    >
      <template #abovecanvas>
        <media-designer-toolbar
          v-if="editorMode==='editor'"
          @paste-ui-template="pasteUiTemplate"
        />
      </template>
    </ab-flow-designer>

    <q-dialog ref="runApp">
      <diagram-emulator :module-id="diagram.module_id" @close="$refs.runApp.hide()" :run-mode="runMode"/>
    </q-dialog>

  </div>
</template>

<script>

import AbFlowDesigner from "ab-flow-designer/src/components/Designer/AbFlowDesigner"
import {Diagram} from "@/../../common/db/Diagram.js"
import {StorageNode} from "@/../../common/db/StorageNode.js"
import {AppStyle} from "@/../../common/db/AppStyle.js"
import {Localization} from '@/../../common/db/Localization';
import {LocalizationMessage} from '@/../../common/db/LocalizationMessage';
import {AppIntegration} from '@/../../common/db/AppIntegration';
import {designerComponentsList} from "@/components/DiagramDesigner/Editor/components/designerComponentsList";
import {nanoid} from "nanoid";
import {computed} from "vue";
import "../styles.scss"
import {treeHelper} from "@/../../common/utils/treeHelper";
import WidgetEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetEditorCmp.vue";
import WidgetPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetPropsCmp.vue";
import { History } from 'stateshot'
import _ from "lodash";
import MediaDesignerToolbar from "@/components/DiagramDesigner/Editor/DiagramDesignerToolbar.vue";
import DiagramEmulator from "@/components/DiagramDesigner/Editor/DiagramEmulator.vue";
import {WidgetProcessor} from "@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetProcessor";
import BuildApp from "@/components/DiagramDesigner/Editor/builder/BuildApp.vue";
import {AppModule} from "../../../../../common/db/AppModule";
import TabCommunicationMixin from '@/mixins/TabCommunicationMixin';

export default {
  name: "DiagramDesigner",
  components: {DiagramEmulator, MediaDesignerToolbar, AbFlowDesigner, BuildApp},

  mixins: [TabCommunicationMixin],

  props: {
    editorMode: {
      default: "editor"
    },
    diagram_id: {},
    product_id: {},
    module_id: {},
    purpose: {
      default: "ui"
    }
  },
  inject: {
    currentModule: {
      default: null
    },
    main: {
      default: null
    },
  },
  provide: function () {
    return {
      designer: this,
      parentWidget: this,
      animation_frame: computed(() => this.animation_frame),
      diagram_id: this.diagram_id,
      product_id: this.product_id,
      module_id: this.module_id
    }
  },
  data: () => ({
    diagram: false,
    runMode: "debug",
    source: false,
    diagram_version: 0,
    current_version: 0,
    animation_frame: 0,
    buildMode: "stage",
    styles: [],
    history: false,
    currentStylesTheme: false,
    canvas_position: {left: `-500000px`, top: `-500000px`},
    isReady: false,
    blockEvents: {},
    deleteConfirmationIsActive: false,
    appLocales: [],
    mainLocale: null,
    localizations: {},
    currentLocale: null,
    canvasFocused: false,
    module: null,
    widgets: [],
  }),

  /**
   * On created
   * @return {Promise<void>}
   */
  async created() {

    // Store himself to global object
    this.app.currentMediaDesigner = this

    // Load diagram
    this.diagram = (await Diagram.remote().subscribe("diagram", {id:this.diagram_id}))?.[0]
    console.log("diagram", this.diagram)

    await Diagram.remote().subscribe('app-module-diagrams', {module_id: this.module_id});

    // Get module
    this.module = await AppModule.find(this.module_id)

    // Subscribe to styles
    const styles = await AppStyle.remote().subscribe("module-styles", {module_id: this.diagram.module_id})
    this.currentStylesTheme = styles?.[0]?.id;

    // Load widgets list
    this.widgets = await Diagram.query().where("app_id", this.product_id).where("diagram_type", "widget").get();

    // Store diagram version
    this.current_version = this.diagram_version = this.diagram.version ? this.diagram.version : 1;

    // Store diagram source to local var
    this.source = this.diagram.source ? this.diagram.source : {id: this.diagram_id, type: 'root', children: []}

    // Set required properties
    this.source.id = this.diagram_id

    // Init history
    this.history = new History();
    this.history.pushSync(this.source)

    // Watch for version
    this.$watch('source', (newSource) => {
      this.current_version++
      this.saveHistory(newSource)
    }, {deep: true})

    // Subscribe localizations integration
    await AppIntegration.remote().subscribe('app-integration-by-name', {
      module_id: this.diagram.module_id,
      name: 'localizations',
    });

    // Subscribe localizations
    await Localization.remote()
      .subscribe("module-localizations", {module_id: this.diagram.module_id});

    // Subscribe localizations messages
    await LocalizationMessage.remote()
      .subscribe("module-localization-messages", {module_id: this.diagram.module_id});

    // Load localizations integration
    const integration = await AppIntegration.query().where({
      module_id: this.module_id,
      name: 'localizations',
    }).first();

    // Set app locales
    this.appLocales = [
      integration?.props?.mainLocale,
      ...(integration?.props?.additionalLocales || []),
    ].filter((v) => !!v);

    // Set main locale
    this.mainLocale = integration?.props?.mainLocale || null;
    // Set current locale
    this.currentLocale = this.mainLocale;

    // Update localizations object
    await this.updateLocalizations();

    // Load app styles
    this.styles = await AppStyle.query().where("module_id", this.diagram.module_id).get()

    // Watch for theme changes
    this.$watch('currentStylesTheme', () => {
      this.applyStyles();
    });

    // Apply styles
    this.applyStyles();

    // Ready
    this.isReady = true;
  },


  methods: {

    /**
     * Add event listener
     * @param id
     * @param func
     */
    addListener(id, func) {
      if(!this.blockEvents[id]) this.blockEvents[id] = []
      this.blockEvents[id].push(func)
    },

    /**
     * Remove event listener
     * @param id
     * @param func
     */
    removeListener(id, func) {
      if(!this.blockEvents[id]) return
      this.blockEvents[id] = this.blockEvents[id].filter(f => f !== func)
    },

    /**
     * Send event
     * @param id
     * @param data
     */
    sendEvent(id, data) {
      for(const func of this.blockEvents[id] || []) func(data)
    },

    /**
     * Get diagram
     * @param id
     * @return {*}
     */
    async getDiagram(id) {
      return Diagram.find(id)
    },

    /**
     * Set source
     * @param source
     */
    setSource(source) {
      this.source = source
    },

    /**
     * Apply theme styles
     */
    applyStyles() {

      // Get styles from current theme style
      //this.currentStyle = this.styles.find(el => el.id === this.currentStylesTheme) || new AppStyle()

      // Apply styles
      setTimeout( () => {
        // Create styles element
        const st = document.createElement("style");
        st.innerHTML = this.currentStyle.getStyles();
        this.$refs.diagramEditor?.appendChild(st)
      }, 100);
    },

    /**
     * Save history
     * @param src
     */
    saveHistory:_.debounce( function (src) {
      if( JSON.stringify(this.history.get()) !== JSON.stringify(src) ) {
        this.history.pushSync(this.source)
      }
    }, 500),

    /**
     * build version
     * @param mode
     */
    buildApp(mode) {
      this.buildMode = mode
      this.$refs.buildApp.show();
    },

    /**
     * Run application
     */
    run(mode) {
      this.runMode = mode
      if(this.diagram.module_id) this.$refs.runApp.show(); else {
        this.$q.notify({
          message: "Please, specify module for this diagram",
          type: "warning"
        })
      }

    },


    /**
     * Undo
     */
    undo() {
      this.history.undo();
      this.source = this.history.get()
    },

    /**
     * Redo
     */
    redo() {
      this.history.redo();
      this.source = this.history.get()
    },

    /**
     * Save current design
     */
    async save() {

      // Save changes into diagram
      await this.diagram.remote().save({
        id: this.diagram.id,
        version: this.current_version,
        source: this.source
      })

      // Set diagram as current
      this.diagram_version = this.current_version

      // Make backup each 10 versions
      await this.app.client.call("service", "backup", "diagram", this.diagram.id);
    },

    /**
     * Generate id
     * @return {string}
     */
    genId() {
      return nanoid(10)
    },

    /**
     * Copy block
     * @param blockId
     */
    copyBlock(blockId) {
      const jsonData = JSON.stringify(this.$refs.editor.getNodeById(blockId));

      // Copy block json
      this.main.copiedBlock = JSON.parse(jsonData);

      // Send copied component to other tabs
      this.sendMessageToTab('copy-component', jsonData);
    },

    /**
     * Duplicate fragment widgets
     * @param schema
     * @return {Promise<*>}
     */
    async duplicateFragmentWidgets(schema) {
      // Find all widgets in the schema
      const widgetsId = new Set;

      // Find all widgets in the schema
      treeHelper.traverseTree(schema, (node) => {
        if (node?.type && node.type.startsWith('Widget:')) {
          const [,widgetId] = node.type.split(':');

          widgetsId.add(widgetId);
        }
      });

      const needPatchIds = new Map;

      // Find or duplicate widgets
      for (const widgetId of [...widgetsId.values()]) {
        const diagram = (await Diagram.remote().subscribe("diagram", {id:widgetId}))?.[0];

        if (!diagram?.id) {
          throw `Error while finding widget diagram: ${widgetId}. It may have been deleted`;
        }

        // Find or duplicate widget
        let moduleWidget = (await Diagram.query().where({
          module_id: this.module_id,
          diagram_type: 'widget',
          unique_id: diagram.unique_id,
        }).get())?.[0];

        // Duplicate widget
        if (!moduleWidget) {
          moduleWidget = await Diagram.duplicate(widgetId, this.product_id, this.module_id);

          if (!moduleWidget) {
            throw 'Error while duplicating widget';
          }

          this.widgets.push(moduleWidget);
        }

        needPatchIds.set(widgetId, moduleWidget.id);
      }

      // Patch widget ids
      treeHelper.traverseTree(schema, (node) => {
        if (node?.type && node.type.startsWith('Widget:')) {
          const [,widgetId] = node.type.split(':');

          if (needPatchIds.has(widgetId)) {
            node.title = `Widget:${needPatchIds.get(widgetId)}`;
            node.type = `Widget:${needPatchIds.get(widgetId)}`;
          }
        }
      });

      return schema;
    },

    /**
     * Paste block
     * @param target
     */
    async pasteBlock({target}) {
      // Source and target
      let src = this.main.copiedBlock//JSON.parse(JSON.stringify(this.$refs.editor.getNodeById(source)))
      if(!src) {
        console.error("No source block to paste")
      }

      if (src.type === 'Fragment') {
        src = await this.duplicateFragmentWidgets(src);
      }

      // Get target parent
      let par = this.$refs.editor.getNodeParentsById(target)
      if (par.length > 1) par = par[par.length - 2]; else par = this.source

      // Regenerate ids
      treeHelper.traverseTree(src, el => {
        el.id = this.genId()
      });

      /**
       * Patch localization aliases
       * @param tree
       */
      const patchLocalizationAliases = (tree) => {
        // Check if the block is marked as localizable and if it has a localeAlias
        if (tree?.localeAlias) {
          tree.localeAlias = this.genId();
        }

        // Go deeper
        for (const prop of Object.values(tree || {})) {
          if (typeof prop === 'object') {
            patchLocalizationAliases(prop);
          }
        }
      }

      // Patch localization aliases
      patchLocalizationAliases(src);

      // Check if top block is fragment - move it a little
      if (src.type === 'Fragment') {
        src.x = -parseInt(this.canvas_position.left) + 100
        src.y = -parseInt(this.canvas_position.top) + 100
      } else if (src?.x && src?.y) {
        src.x = parseInt(src.x) + 10
        src.y = parseInt(src.y) + 10
      }

      // Add component as child
      par.children.push(src)

      // Reset block for copying
      this.main.copiedBlock = false;

      // Send paste event to other tabs
      this.sendMessageToTab('paste-component');
    },

    /**
     * Paste ui template
     * @param schema
     */
    async pasteUiTemplate(schema) {
      try {
        this.main.copiedBlock = schema;

        await this.pasteBlock({ target: this.source.id });
      } catch (e) {
        console.error('Error while pasting UI template:', e);

        this.$q.notify({
          message: typeof e === 'string' ? e : e?.message || 'Error while pasting UI template',
          type: "negative"
        })
      }
    },

    /**
     * On canvas moved
     * @param position
     */
    canvasMoved(position) {
      this.canvas_position = position
    },


    /**
     * Wrap block
     * @param to
     * @param event
     */
    wrapBlock({to, component, position = 0}) {

      // Get node parents
      let parent = this.$refs.editor.getNodeParentById(to.block.id);

      // Add new block to parent
      const newId = this.addBlock({to: {block: parent}, component, position, after: to.block.id})

      // Move old block to new block
      this.moveToParent({source: to.block.id, target: newId})

    },


    /**
     * Add new block
     * @param to
     * @param event
     */
    addBlock({to, component, position, after}) {

      // To block
      const toBlock = to.parentWidget?.block || to.block;

      // Add to parent
      if (!toBlock.children) toBlock.children = []

      // To children
      let toChildren = toBlock.children;

      // If we are inside widget - get children specific container
      /*if (to.parentWidget) {
        let cnt = toChildren.find(e => e.alias === to.block.properties?.alias)
        if (!cnt) {
          cnt = {
            type: "RemoteChildren",
            title: to.block.title,
            id: to.block.id+to.block.properties?.alias,
            alias: to.block.properties?.alias,
            allowed: ['*'],
            children: []
          };
          toChildren.push(cnt)
        }

        // New container
        toChildren = cnt.children;
      }*/

      // Get new id
      const newId = this.genId()

      // Create new block
      const new_block = {
        id: newId,
        title: component.type,// + " #" + newId,
        type: component.type
      }

      // Add position to root children
      if (toBlock.type === 'root') {
        new_block.x = position.x
        new_block.y = position.y
      }

      // new children list
      const nList = []

      // Add source to target after
      for (const itm of toChildren) {

        // Add item to result list
        nList.push(itm)

        // Add new node after
        if (after && itm.id === after) nList.push(new_block)
      }

      // Add component as child
      if(!after) nList.push(new_block)

      // Set new children list to the target
      toChildren.length=0
      toChildren.push(...nList)

      // Select added block
      this.$refs.editor.selectObjectIds([newId])

      // Return new id
      return newId
    },

    selectObject(id) {
      this.$refs.editor.selectObjectIds([id]);
    },

    /**
     * Update block
     */
    updateBlock({id, params}) {
      const cmp = this.$refs.editor.getNodeById(id)
      for (const k of Object.keys(params)) cmp[k] = params[k]
    },

    /**
     * Add link
     */
    addLink(connection) {

      // Check if source != target
      if (connection.source?.id === connection.target?.id) {
        this.$q.notify({
          message: "Source and target should be different",
          type: "warning"
        })
        return
      }

      // Construct link
      const link = {
        type: "link",
        parent_id: 0,
        properties: {
          connection
        }
      }

      // Generate link id
      link.id = Diagram.generateLinkId(link);

      // Check if link already exists
      if (this.source.children.filter((v) => v.type === 'link').some((v) => {
        const lId = link.properties.connection.source.id + '-' + link.properties.connection.source.event + '-' + link.properties.connection.source.unique + '->' +
            link.properties.connection.target.id + '-' + link.properties.connection.target.event + '-' + link.properties.connection.target.unique;

        const vId = v.properties.connection.source.id + '-' + v.properties.connection.source.event + '-' + v.properties.connection.source.unique + '->' +
            v.properties.connection.target.id + '-' + v.properties.connection.target.event + '-' + v.properties.connection.target.unique;

        return (lId === vId)
      })) {
        this.$q.notify({
          message: "Link already exists",
          type: "warning"
        });
        return;
      }

      console.log("new link", link)

      // Create new block
      this.source.children.push(link)
    },

    /**
     * Set block property
     * @param block_id
     * @param type
     * @param data
     */
    setProperty({block_id, type, data}) {

      // Get component
      const cmp = this.$refs.editor.getNodeById(block_id)

      // Get or create properties
      if (!cmp.properties) cmp.properties = {}

      // Delete items
      if (data === null) {
        const opts = {}
        for (const pr of Object.keys(cmp.properties)) if (pr !== type) opts[pr] = cmp.properties[pr]
        cmp.properties = opts
      } else {
        // Set property
        cmp.properties[type] = data
      }
    },

    /**
     * Delete block with prompt
     */
    deleteBlockWithPrompt(ids) {
      // Check if already active
      if (this.deleteConfirmationIsActive) {
        return;
      }

      // Set active
      this.deleteConfirmationIsActive = true;

      // Ask first
      this.$q.dialog({
        message: "Are you sure want to delete block?",
        cancel: true
      }).onOk(() => {
        for (const id of Array.isArray(ids) ? ids : [ids]) {
          this.deleteBlock(id)
        }
      }).onDismiss(() => {
        // Set inactive
        this.deleteConfirmationIsActive = false;
      });
    },

    /**
     * Delete block logic
     * @param id
     */
    deleteBlock(id) {
      // Get parents
      let par = this.$refs.editor.getNodeParentsById(id)
      if (par?.length > 1) par = par[par?.length - 2]; else par = this.source

      par.children = par.children.filter(item => {
        // Delete Block
        if (item.id === id) {
          return false;
        }
        // Delete link
        if (item.type === 'link' && (item.properties.connection.source.id === id || item.properties.connection.target.id === id)) {
          return false;
        }
        return true;
      })
    },

    /**
     * Move block to block
     */
    moveToParent({source, target, after}) {

      // Load components
      const src = this.$refs.editor.getNodeById(source);

      // Delete source from original location
      this.deleteBlock(source)

      // Get new parent
      const trg = this.$refs.editor.getNodeById(target);

      // New list
      const nList = []

      // Init target children list
      if (!trg.children) trg.children = []

      // Add source to target after
      for (const itm of trg.children) {

        // Add item to result list
        nList.push(itm)

        // Add new node after
        if (after && itm.id === after) nList.push(src)
      }

      // Add component at the end
      if (!after) nList.push(src)

      // Set new children list to the target
      trg.children = nList
    },

    onSelect(ids) {
      console.log("!", ids)
    },

    /**
     * Copy block
     *
     * @param {KeyboardEvent} event - The keyboard event triggered by the user.
     */
    hotkeyCopyBlockProcessor(event) {
      // Check if the event target is an input, textarea, select, or button element.
      // If it is, return and do nothing.
      if (event.target.closest('input, textarea, select, button')) {
        return;
      }

      // Get the ID of the currently selected node in the editor.
      const nodeId = this.$refs?.editor?.selectedObjectIds[0] || undefined;

      // If no node is currently selected, return and do nothing.
      if (!nodeId) {
        return;
      }

      // Prevent the default copy action.
      event.preventDefault();

      // Call the `copyBlock` method with the ID of the selected node.
      this.copyBlock(nodeId)
    },

    /**
     * Paste block
     *
     * @param {KeyboardEvent} event - The keyboard event triggered by the user.
     */
    hotkeyPasteBlockProcessor(event) {
      // Get the ID of the currently selected node in the editor.
      const nodeId = this.$refs?.editor?.selectedObjectIds[0] || undefined;

      if (!nodeId || !this.main.copiedBlock) {
        return;
      }

      // Prevent the default paste action.
      event.preventDefault();

      // Call the `pasteBlock` method with the ID of the selected node.
      this.pasteBlock({target: nodeId})
    },

    /**
     * Global hotkeys processor.
     *
     * @param {KeyboardEvent} event - The keyboard event triggered by the user.
     */
    hotkeysProcessor(event) {
      const keySeq = [
        event.ctrlKey ? 'ctrl' : '',
        event.altKey ? 'alt' : '',
        event.metaKey ? 'meta' : '',
        event.shiftKey ? 'shift' : '',
        event.key
      ].filter((v) => !!v)
        .join('-')
        .toLowerCase();

      // Check if the canvas is currently focused.
      if (!this.canvasFocused) {
        return;
      }

      switch (keySeq) {
        case 'ctrl-z':
        case 'meta-z':
          this.app.currentMediaDesigner.undo();
          break;
        case 'ctrl-shift-z':
        case 'meta-shift-z':
          this.app.currentMediaDesigner.redo();
          break;
        case 'ctrl-c':
        case 'meta-c':
          this.hotkeyCopyBlockProcessor(event);
          break;
        case 'ctrl-v':
        case 'meta-v':
          this.hotkeyPasteBlockProcessor(event);
          break;
        default:
          break;
      }
    },

    /**
     * This method is a listener for the 'beforeunload' event.
     * It is triggered when the page is about to be refreshed or closed.
     * This is to ensure that the user does not accidentally lose their work.
     *
     * @param {BeforeUnloadEvent} e - The 'beforeunload' event object.
     * @returns {string|undefined} - Returns a confirmation message if there are unsaved changes, otherwise does nothing.
     */
    beforeUnloadListener(e) {
      // Check if the current version of the diagram is the same as the saved version.
      // If they are the same, it means there are no unsaved changes, so we do nothing and return.
      if (this.current_version === this.diagram_version) {
        return;
      }

      // If there are unsaved changes, we prevent the default action of the event.
      e.preventDefault();

      // Chrome requires the returnValue property of the event to be set in order to show the confirmation dialog.
      e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';

      // Return the confirmation message that will be shown to the user.
      return 'You have unsaved changes. Are you sure you want to leave?';
    },

    /**
     * Updates the localizations for the current module.
     *
     * @returns {Promise<void>} A promise that resolves when the localizations have been updated.
     */
    async updateLocalizations() {
      // It queries the `Localization` model for localizations where the `module_id` matches the current `module_id`.
      const localizations = await Localization.query().where({module_id: this.module_id}).get();
      // It retrieves a list of localization messages for the current `module_id`.
      // It then reduces the list of messages into an object where each key is a locale
      // and each value is an object of localization messages for that locale.
      const messages = (await LocalizationMessage.getList(this.module_id) || []).reduce((res, message) => {
        if (!res[message.locale]) {
          res[message.locale] = {};
        }

        res[message.locale][message.localization_id] = message.message;

        return res;
      }, {});

      // Finally, it reduces the list of app locales into an object where
      // each key is a locale and each value is an object of localizations for that locale.
      this.localizations = this.appLocales.reduce((res, locale) => {
        res[locale] = localizations.reduce((result, localization) => {
          result[localization.alias] = messages[locale]?.[localization.id] || null;

          return result;
        }, {});

        return res;
      }, {});
    },

    /**
     * This method is a listener for the 'click' event.
     * It is triggered when the user clicks anywhere in the document.
     * It checks if the clicked element or any of its parents is a part of the '.editor-canvas' element.
     * If it is, it sets the 'canvasFocused' data property to true, indicating that the canvas is currently focused.
     * If it is not, it sets the 'canvasFocused' data property to false, indicating that the canvas is not currently focused.
     *
     * @param {MouseEvent} e - The 'click' event object.
     */
    canvasFocusedListener(e) {
      this.canvasFocused = !!e.target.closest('.editor-canvas');
    },

    /**
     * Localizes a given variable node based on the current locale.
     *
     * @param {Object} node - The variable node to localize. The node should have a 'type', 'is_localizable', and 'locale_alias' property.
     * @returns {string|Object} The localized value of the node, or the node's value if it is not localizable or no localization is found. If a localization is found, an object is returned containing the node's value, a flag indicating that the node is localizable, and the node's locale alias.
     */
    localizeVariable(node) {
      // Check if the node is localizable.
      const isLocalizable = (node.type === 'string') && node.is_localizable && node.locale_alias;

      // If the node is not localizable, return the node's value.
      if (!isLocalizable) {
        return node?.value;
      }

      // Get the current locale.
      const currentLocale = this.currentLocale || this.mainLocale;

      // Get the localizations for the current locale.
      const localizations = this.localizations[currentLocale] || {};

      // Get the localization for the node's locale alias.
      if (!localizations[node.locale_alias]) {
        return node?.value;
      }

      // Return an object containing the node's value, a flag indicating that the node is localizable, and the node's locale alias.
      return {
        value: node?.value,
        isLocalizable: true,
        localeAlias: node.locale_alias,
      };
    },

    /**
     * Handle messages received from other tabs.
     * @param {Event} e - The event object containing the message data.
     */
    handleTabMessage(e) {
      const {type, content} = e.data;

      switch (type) {
        case 'copy-component':
          this.main.copiedBlock = JSON.parse(content);
          break;
        case 'paste-component':
          this.main.copiedBlock = false;
          break;
      }
    },

    /**
     * Centers the editor on the coordinates specified in the route query.
     * The coordinates should be in the format `x;y`.
     * If the coordinates are not valid or not provided, the function will return without making any changes.
     */
    centerOnCoordinates() {
      if (!this.$refs.editor) {
        return;
      }

      const {position} = this?.$route?.query || {};

      if (!position) {
        return;
      }

      const [x, y] = position.split(';').map(Number);

      if (!x || !y) {
        return;
      }

      this.$refs.editor.centerOnCoordinates({x, y});
    },

    /**
     * Focuses on a specific block in the editor.
     *
     * This method centers the editor on the block specified in the route query
     * and selects the block.
     */
    focusOnBlock() {
      if (!this.$refs.editor) {
        return;
      }

      const {blockId} = this?.$route?.query || {};

      if (!blockId) {
        return;
      }

      this.$refs.editor.centerOnBlock(blockId);
      this.$refs.editor.selectObjectIds([blockId]);
    },
  },

  computed: {

    /**
     * Has paste
     */
    hasPaste() {
      return !!this.main.copiedBlock
    },

    /**
     * Get current style
     * @return {AppStyle}
     */
    currentStyle() {
      // Get styles from current theme style
      return this.styles.find(el => el.id === this.currentStylesTheme) || new AppStyle()
    },

    /**
     * Get storage data
     * @return {*}
     */
    storageData() {
      return this.wait("storageData", StorageNode.getTree(this.module_id, `diagram-${this.diagram_id}`, this.localizeVariable), {})
    },

    /**
     * Get constant storage data
     * @return {*}
     */
    constantStorageData() {
      return this.wait("constantStorageData", StorageNode.getAppTree(this.app_id, 0, `constants`, this.localizeVariable), {})
    },


    /**
     * Get storage data
     * @return {*}
     */
    appStorageData() {
      return this.wait("appStorageData", StorageNode.getTree(this.module_id, 'app-storage', this.localizeVariable), {})
    },


    /**
     * Widget storage
     * @return {{get: (function(*): never)}}
     */
    storage() {
      return {
        get: (key) => {
          return _.get(this.storageData, key)
        }
      }
    },


    /**
     * Widget storage
     * @return {{get: (function(*): never)}}
     */
    constantStorage() {
      return {
        get: (key) => {
          return _.get(this.constantStorageData, key)
        }
      }
    },

    /**
     * Widget storage
     * @return {{get: (function(*): never)}}
     */
    appStorage() {
      return {
        get: (key) => {
          //console.error(this.appStorageData, key)
          return _.get(this.appStorageData, key)
        }
      }
    },


    /**
     * Return all styles of app
     * @return {*}
     */
    /*styles() {
      return this.wait("styles", AppStyle.query().where("app_id", this.product_id).get(), [])
    },*/

    /**
     * Return all style titles
     * @return {*}
     */
    styleTitles() {
      return this.styles.map(el => ({value: el.id, label: el.title}))
    },


    /**
     * Css properties
     */
    cssProps() {
      return {
        //position: "absolute",
        "--fragmentWidth": "300px",
        "--fragmentHeight": "400px",
      }
    },

    /**
     * Check if has changes
     * @return {boolean}
     */
    hasChanges() {
      return this.current_version !== this.diagram_version
    },

    /**
     * Get components list
     * @return {*[]}
     */
    componentsList() {
      // Define diagram purpose
      let purpose = this.purpose

      if (!Array.isArray(purpose)) {
        purpose = [purpose];
      }

      // Set purpose for chat-bot and server
      switch (this.module.type) {
        case 'chat-bot':
          purpose = ['logic', 'chat-bot']
          break;
        case 'server':
          purpose = ['logic']
          break;
      }

      // Set purpose for function diagram
      if (['function', 'process'].includes(this.diagram.diagram_type)) {
        purpose = ['logic']
      }

      // Fill components list according to diagram type
      const list = [
        ...[{
          title: "Widgets",
          type: 'g-widgets',
          purpose: ['ui'],
          expanded: true,
          children: this.widgets.map(d => ({
            title: d.title,
            type: `Widget:${d.id}`,
            component: WidgetEditorCmp,
            properties: WidgetPropsCmp,
            processor: WidgetProcessor
          }))
        }],
        ...designerComponentsList
      ].filter(g => g.purpose?.some((v) => purpose.includes(v)) )

      // Filter components children
      for (const groupKey in list) {
        list[groupKey].children = list[groupKey].children?.filter(c => !c.purpose || c.purpose?.some((v) => purpose.includes(v)))
      }

      return list;
    },
  },

  mounted() {
    // Add hotkey listener
    document.addEventListener('keydown', this.hotkeysProcessor);

    // Add before unload listener
    window.addEventListener('beforeunload', this.beforeUnloadListener);

    // Add canvas focused listener
    document.addEventListener('click', this.canvasFocusedListener, true);

    const unwatch = this.$watch(() => this.isReady, () => {
      this.$nextTick(() => {
        // Center on coordinates
        this.centerOnCoordinates();

        // Focus on block
        this.focusOnBlock();

        unwatch();
      });
    });
  },

  beforeUnmount() {
    // Remove hotkey listener
    document.removeEventListener('keydown', this.hotkeysProcessor);

    // Remove before unload listener
    window.removeEventListener('beforeunload', this.beforeUnloadListener);

    // Remove canvas focused listener
    document.removeEventListener('click', this.canvasFocusedListener, true);
  }
}

</script>

<style lang="scss">

.diagram-designer {

  .flow-tools {
    background: #333a;
    color: white;
    border-radius: 10px;
  }

  .editor-cmp {
    min-height: 1em;
    min-width: 1em;

    //outline: 1px dotted transparent;
    //border: 1px dashed transparent;

    .mover {
      background: #666;
    }

    .ev-run {
      left: -12px;
      position: absolute;
      z-index: 1;
      top: calc(50% - 5px);
    }
    .results {
      right: -12px;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      row-gap: 6px;
    }
  }

  .editor-cmp.selected, .editor-cmp.hovered {
    position: relative;
    //z-index: 1;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      border: 2px solid #aa0000;
      z-index: 99;
    }
  }

  .container-editor-cmp.dg-direction-stack>.editor-cmp {
    &.selected, &.hovered {
      position: absolute;
    }
  }

  .editor-cmp.hovered {
    &:before {
      border-color: #00aa00;
    }
  }

  //height: 1px;

  .main-container {
  }

  .canvas-links {
    overflow: visible;
  }

  .connector-left {
    position: absolute;
    top: 50%;
    left: -15px;
  }

  .connector-right {
    position: absolute;
    top: 50%;
    right: -15px;
  }

  .connector-top {
    position: absolute;
    top: -15px;
    left: 50%;
  }

  .connector-bottom {
    position: absolute;
    bottom: -15px;
    left: 50%;
  }

  .connector-center {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .hide-child-connectors {
    pointer-events: none;
    .link-connector {
      display: none;
    }
  }

}

</style>
