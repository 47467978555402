<template>

  <q-card style="width: 400px;">
    <q-card-section>
      <div class="text-h6">Edit release</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="releaseForm" @submit="onSaveRelease" class="q-gutter-md">

        <q-input
            v-model="mutate(editRelease).name"
            label="Release version name *"
            hint="Release version"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {AppRelease} from "@/../../common/db/AppRelease";

export default {
  name: "ReleaseForm",
  props: ['editRelease'],
  emits: ['saved'],
  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveRelease() {

      // Show loading
      this.$q.loading.show("Creating release")

      try {

        // Update
        //await AppRelease.remote().save(this.editRelease)
        await AppRelease.remote().call("app", "createRelease", this.editRelease.module_id, this.editRelease.name, this.editRelease.code_version)

        // Show toast
        this.$q.notify({
          message: "Release created",
          color: "positive",
          icon: "check"
        });

      } catch (e) {
        this.$q.notify({type: 'negative', message: typeof e === 'string' ? e : e?.error || e.message})
      } finally {
        this.$q.loading.hide()
      }

      // Hide dialog
      this.$emit('saved')
    }
  }
}

</script>
